const consts = {
    SET_PAYMENT_STATUSES:           "OPTION-SET_PAYMENT_STATUSES",
    SET_PAYMENT_TYPES:              "OPTION-SET_PAYMENT_TYPES",
    SET_BOOKING_TYPES:              "OPTION-SET_BOOKING_TYPES",
    SET_BOOKING_STATUSES:           "OPTION-SET_BOOKING_STATUSES",
    SET_BOOKING_TIME_STATUSES:      "OPTION_SET_BOOKING_TIME_STATUSES",
    SET_BOOKING_TIME_TYPES:         "OPTION_SET_BOOKING_TIME_TYPES",
};
  
export default consts;
