//Реакт
import React, { useState, useEffect } from "react";
//Стили
import './styles.css'

export default function ContentGroupBox({ icon, count = null, toggle = false, className = '', changeStatus, children, ...other }) {
    const [showContent, setShowContent] = useState(true);

    const handleMouseEnter = () => {
        if (!toggle) setShowContent(true);
    };

    const handleMouseLeave = () => {
        if (!toggle) setShowContent(false);
    };

    const handleClickOpen = () => {
        if (toggle) setShowContent(!showContent);
    };

    useEffect(() => {
        setShowContent(!showContent);
    }, [changeStatus]);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            // Проверяем, был ли клик вне компонента
            if (toggle && !event.target.closest(".content-group-box")) {
                setShowContent(false);
            }
        };

        if (toggle) {
            document.addEventListener("click", handleDocumentClick);
        }

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [toggle]); // Зависимость от toggle

    const handleChildClick = (event) => {
        // Останавливаем всплытие события, чтобы клик не доходил до document
        event.stopPropagation();
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClickOpen}
            className={`${className} content-group-box`}
        >
            <div className="content-group-box__icon">
                {icon ?? null}
            </div>
            {count != null ? <div className="content-group-box__text">{count > 100 ? '99+' : count}</div> : null}
            {showContent && (
                <div className="content-group-box__dropdown" onClick={handleChildClick}>
                    {children}
                </div>
            )}
        </div>
    );
}