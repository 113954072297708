//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useLocation } from 'react-router-dom'
//Библиотеки и Api
import ViewHandler from "../../../../../inc/ViewHandler";
import ApiBookingTime from "../../../../../app/api/authorized/admin/booking/ApiBookingTime";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
//Mui icons
import DifferenceIcon from '@mui/icons-material/Difference';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonIcon from '@mui/icons-material/Person';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import CreditCardIcon from '@mui/icons-material/CreditCard';
//Стили
import './styles.css';

export default function BookingTimeIndex({ ...other }) {
    return <Page {...other}>
        <BookingTimeIndexContent {...other} />
    </Page>
}

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function BookingTimeIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_type, set_type] = useState('');
    const [_fio, set_fio] = useState('');

    const query = useQuery()

    useLayoutEffect(() => {
        contextPage.setTitle(`Транзакции`);
        contextContent.setTitle(`Транзакции`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="user"
            name="user"
        >
            <div className="header-row__center">
                <PersonIcon/>
                <div className="header-row__center_text">Пользователь</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="balanceType"
            name="balanceType"
        >
            <div className="header-row__center">
                <DifferenceIcon/>
                <div className="header-row__center_text">Тип баланса</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            <div className="header-row__center">
                <DifferenceIcon/>
                <div className="header-row__center_text">Минуты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="userBalance"
            name="userBalance"
        >
            <div className="header-row__center">
                <DifferenceIcon/>
                <div className="header-row__center_text">Баланс пользователя</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
        >
            <div className="header-row__center">
                <WebStoriesIcon/>
                <div className="header-row__center_text">Тип операции</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус операции</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="paymentType"
            name="paymentType"
        >
            <div className="header-row__center">
                <CreditCardIcon/>
                <div className="header-row__center_text">Способ оплаты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_fio} onChange={(e) => { set_fio(e.target.value) }}>Пользователь</InputText>,
        <InputText className="input_wf" value={_type} onChange={(e) => { set_type(e.target.value) }}>Тип</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-times_column-width" key="user" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}><a href={route(`authorized.admin.user.users.show`,{id: row.user_id})}>{row.user_fio}</a></ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="balanceType" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.user_balance_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="minutes" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="user_balance" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.user_balance_balance}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="type" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="status" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row.status_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="paymentType" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-times_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBookingTime}
            renderRow={renderRow}
            searchParams={{ userFio: _fio, type: _type, balanceId: query.get('balanceId') ?? null} }
        />
    </>
}
