//Реакт
import React, { useState } from 'react';
import { ProviderBalance } from './balanceProvider';
import { useBalanceContext } from './balanceContext';
//компоненты
import StepBuy from './steps/buy/step-buy';
import StepPreview from './steps/preview/step-preview';
import StepAfterpay from './steps/afterpay/step-afterpay';
import BalanceHeader from './components/balance-header/balance-header';
//Стили
import './styles.css'

export default function BuyBalanceWithProvider({children, className='', apiBuyBalance = null, ...other}) {
    return (
        <ProviderBalance {...other}>
            {children}
            <div className={`${className}`}>
                <BuyBalanceComponent 
                    apiBuyBalance={apiBuyBalance} 
                />
            </div>
        </ProviderBalance>
    );
}

function BuyBalanceComponent({className = '', apiBuyBalance}) {
    const [step, setStep] = useState(1);
    const [_previewData, set_previewDara] = useState(null)
    const { buyBalanceData , fetchBalances} = useBalanceContext();

    const { promocode, balanceId, balanceOptionId, user} = buyBalanceData;
    const { promocodeString } = promocode;
    const { name, email, phone } = user

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const goToFirst = () => {
        setStep(1)
    };

    const handleBuyBalance = () => {
        const fetchData = async () => {
            try {
                const apiPurchase = apiBuyBalance.Update(balanceId, {product_id: balanceOptionId});
                const responseData = await apiPurchase.getResponse();
                if (responseData) {
                    if(responseData.url) {
                        window.open(responseData.url);
                    }
                    // setStep(3);
                }
            } catch (error) {}
        };

        if(apiBuyBalance) fetchData();
    }

    const handleCreateBalance = () => {
        const fetchData = async () => {
            try {
                const apiPurchase = apiBuyBalance.Store({balance_type_id: balanceId,product_id: balanceOptionId});
                const responseData = await apiPurchase.getResponse();
                if (responseData) {
                    if(responseData.url) {
                        window.open(responseData.url);
                    }
                    // setStep(3);
                }
            } catch (error) {}
        };

        if(apiBuyBalance) fetchData();
    }
        
    const steps = [
        <StepBuy nextStep={nextStep} handleBuyBalance={handleBuyBalance} handleCreateBalance={handleCreateBalance}/>,
        <StepPreview nextStep={nextStep} prevStep={prevStep} previewData={_previewData}/>,
        <StepAfterpay prevStep={prevStep}  goToFirst={goToFirst}/>
    ];

    return (
        <>
            <div className={`buy_balance__card ${className}`}>
                <div className="buy_balance__card-header">
                    <BalanceHeader prevStep={prevStep} step={step}/>
                </div>
                <div className="buy_balance__card-body">
                    {steps[step - 1] || <>Error: Unknown step</>}
                </div>
            </div>
        </>
    );
}