//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiTeamCompetition from "../../../../../app/api/authorized/admin/event/ApiTeamCompetition.js"
import ViewHandler from "../../../../../inc/ViewHandler";
import { route } from "../../../../../inc/Routes"
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputPhone from "../../../../components/inputs/phone/phone";
import Select from "../../../../components/inputs/select";
import ButtonLinkShow from "../../../../components/button-link/__show";
import MultiSelect from "../../../../components/inputs/milti-select";
// Mui icons
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';  
import Groups2Icon from '@mui/icons-material/Groups2';
import GroupIcon from '@mui/icons-material/Group';
import PinIcon from '@mui/icons-material/Pin';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import PhoneIcon from '@mui/icons-material/Phone';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import ContactlessIcon from '@mui/icons-material/Contactless';
//Стили
import './styles.css';

export default function CompetitionTeamIndex({ ...other }) {
    return <Page {...other}>
        <CompetitionTeamIndexContent {...other} />
    </Page>
}

function CompetitionTeamIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_orderId, set_orderId] = useState('');
    const [_name, set_name] = useState('');
    const [_competition, set_competition] = useState('');
    const [_discipline, set_discipline] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');

    const competitions = useSelector(state => state.adminOption.competitions);
    const disciplines = useSelector(state => state.adminOption.competitionTeamDisciplines);

    const handleCompetitionChange = (event) => {
        set_competition(event.target.value);
    };

    const handleDisciplineChange = (event) => {
        set_discipline(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Команды`);
        contextContent.setTitle(`Команды`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Заказ</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamName"
            name="competitionTeamName"
        >
            <div className="header-row__center">
                <Groups2Icon/>
                <div className="header-row__center_text">Команда</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="show"
            name="show"
        >
            <div className="header-row__center">
                <GroupIcon/>
                <div className="header-row__center_text">Участники</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamId"
            name="competitionTeamId"
            isSort={false}
        >
            <div className="header-row__center">
                <PinIcon/>
                <div className="header-row__center_text">Номер команды</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamCity"
            name="competitionTeamCity"
        >
            <div className="header-row__center">
                <LocationCityIcon/>
                <div className="header-row__center_text">Город</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competition"
            name="competition"
            isSort={false}
        >
            <div className="header-row__center">
                <SportsScoreIcon/>
                <div className="header-row__center_text">Соревнование</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamDiscipline"
            name="competitionTeamDiscipline"
        >
            <div className="header-row__center">
                <SportsGymnasticsIcon/>
                <div className="header-row__center_text">Дисциплина</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            <div className="header-row__center">
                <PhoneIcon/>
                <div className="header-row__center_text">Телефон</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            <div className="header-row__center">
                <EmailIcon/>
                <div className="header-row__center_text">Email</div> 
            </div>
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_orderId} onChange={(e) => { set_orderId(e.target.value) }}>Заказ</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Команда</InputText>,
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер команды</InputNumber>,
        <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <Select className="input_wf" options={competitions} value={_competition} onChange={handleCompetitionChange}>Соревнование</Select>,
        <Select className="input_wf" options={disciplines} value={_discipline} onChange={handleDisciplineChange}>Дисциплина</Select>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
    ];
    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-competitions_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>
                <a target="_blank" href={route("authorized.admin.order.competitions.index", {id: row.order_id})}>{row.order_id}</a>    
            </ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamName" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>
               <a target="_blank" href={row.url}>{row.name}</a>
            </ListBodyItem>,
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamId" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamCity" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.city}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competition" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row?.competition?.name}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamDiscipline" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row?.discipline?.name}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="phone" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="email" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{row.email}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiTeamCompetition}
            renderRow={renderRow}
            searchParams={
                { 
                    id: _id,
                    order_id: _orderId,
                    name: _name, 
                    competition: _competition,
                    discipline: _discipline,
                    phone: _phone, 
                    email: _email, 
                }
            }
        />
    </>
}
