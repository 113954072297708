//Реакт
import React from 'react';
//Mui Icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//Стили
import "./styles.css";
import "../../styles.css";

export default function Info({
    children,
    className = '',
    messages = [],
    ...other
}) {
    return <>
        {messages.length > 0 ?
            <div className="step-buy__tooltip">
                {messages.map((message) => {
                    return <>
                        <div className="step-buy__tooltip-data">
                            <div className="step-buy__tooltip-data-icon">
                                <InfoOutlinedIcon />
                            </div>
                            <div className="step-buy__tooltip-data-label">
                                {message}
                            </div>
                        </div>
                    </>
                })}
            </div>
        : null}
    </>
}
