//Реакт
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Хранилище
import { setClientRoles } from "../../../../../app/reducers/admin/option/actions";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiUser from "../../../../../app/api/authorized/admin/user/ApiUser";
import { route } from "../../../../../inc/Routes";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputEmail from "../../../../components/inputs/email/email";
import InputPhone from "../../../../components/inputs/phone/phone";
import MultiSelect from "../../../../components/inputs/milti-select";
import MyModal from "../../../../components/modals/modal/modal";
import InputWrapper from "../../../../components/input/__wrapper/input__wrapper";
import CheckboxBlock from "../../../../components/checkbox-block";
//Mui icons
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BadgeIcon from '@mui/icons-material/Badge';
import NumbersIcon from '@mui/icons-material/Numbers';
//Стили
import './styles.css';

export default function ClientIndex({ ...other }) {
  return <Page {...other}>
    <UserIndexContent {...other} />
  </Page>
}

function UserIndexContent({ ...other }) {
  const contextContent = useContext(ContextContent);
  const contextPage = useContext(ContextPage);
  const dispatch = useDispatch();

  const [_id, set_id] = useState('');
  const [_name, set_name] = useState('');
  const [_middlename, set_middlename] = useState('');
  const [_email, set_email] = useState('');
  const [_phone, set_phone] = useState('');
  const [_surname, set_surname] = useState('');
  const [_roleIds, set_roleIds] = useState([]);
  const [_isActionLoading, set_isActionLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clientRoles = useSelector(state => state.adminOption.clientRoles)
  const _roleSlugs = Object.values(clientRoles);
  const roles = useSelector(state => state.adminOption.roles)

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSetSelectedItems = (updatedItems) => {
    const filteredItems = Object.keys(updatedItems).reduce((acc, index) => {
        const key = updatedItems[index];
        if (roles[key]) {
            acc[key] = roles[key]; // Добавляем в объект с нужным форматом
        }
        return acc;
    }, {})
    dispatch(setClientRoles(filteredItems))
  };

  // Фильтруем роли по идентификаторам
  const filteredRoles = Object.entries(roles)
    .filter(([id]) => Object.keys(clientRoles).includes(id)) // Сравниваем идентификаторы
    .reduce((acc, [id, name]) => {
      acc[id] = name; // Формируем новый объект
      return acc;
    }, {});

  const handleSelectClient = (id) => {
    const href = route("authorized.admin.management.clients.show", {id: id})
    window.open(href, "_blank");
  }

  const handlRolesChange = (event) => {
    set_roleIds(event.target.value)
  }

  useLayoutEffect(() => {
    contextPage.setTitle(`Клиенты`);
    contextContent.setTitle(`Клиенты`);
  }, [contextContent, contextPage]);

  const header = [
    <ListHeaderItem
      key="name"
      name="name"
    >
        <div className="header-row__center">
            <BadgeIcon/>
            <div className="header-row__center_text">Имя</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="surname"
      name="surname"
    >
        <div className="header-row__center">
            <BadgeIcon/>
            <div className="header-row__center_text">Фамилия</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="middle_name"
      name="middle_name"
    >
        <div className="header-row__center">
            <BadgeIcon/>
            <div className="header-row__center_text">Отчество</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="email"
      name="email"
    >
        <div className="header-row__center">
            <EmailIcon/>
            <div className="header-row__center_text">Почта</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="phone"
      name="phone"
    >
        <div className="header-row__center">
            <PhoneIcon/>
            <div className="header-row__center_text">Телефон</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="created_at"
      name="created_at"
    >
        <div className="header-row__center">
            <EventAvailableIcon/>
            <div className="header-row__center_text">Создан</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="updated_at"
      name="updated_at"
    >
        <div className="header-row__center">
            <EditCalendarIcon/>
            <div className="header-row__center_text">Изменен</div> 
        </div>
    </ListHeaderItem>,
    <ListHeaderItem
      key="id"
      name="id"
    >
        <div className="header-row__center">
            <NumbersIcon/>
            <div className="header-row__center_text">Номер</div> 
        </div>
    </ListHeaderItem>,
  ];

  const filters = [
    <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Имя</InputText>,
    <InputText className="input_wf" value={_surname} onChange={(e) => { set_surname(e.target.value) }}>Фамилия</InputText>,
    <InputText className="input_wf" value={_middlename} onChange={(e) => { set_middlename(e.target.value) }}>Отчество</InputText>,
    <InputEmail className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Почта</InputEmail>,
    <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
    <MultiSelect className="input_wf option-border-none" options={filteredRoles} value={_roleIds} onChange={handlRolesChange}>Роли</MultiSelect>,
    <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
  ];

  const renderRow = (row, i) => {
    return [
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.name}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="surname" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.surname}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="middle_name" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.middle_name}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="email" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.email}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="phone" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.phone ? '+' : ''}{row.phone}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="created_at" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}}  className="admin-clients_column-width" key="updated_at" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
      <ListBodyItem onClick={() => {handleSelectClient(row.id)}} className="admin-clients_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
    //   <ListBodyItem className="admin-users_column-width" key="show">
    //     {contextContent.checkPermission('show') ? <ButtonLinkShow target="_blank" href={contextPage.routes.show.url({id: row.id})} /> : null}
    //   </ListBodyItem>,
    ];
  }
  return <>
    <div className="clients">
        <List
            header={header}
            filters={filters}
            ApiModel={ApiUser}
            renderRow={renderRow}
            useRowSelect={true}
            isActiomLoading={_isActionLoading}
            searchParams={{ id: _id, roleIds: _roleIds ,clientRoleIds: Object.keys(clientRoles), name: _name, middleName: _middlename, surname: _surname, email: _email, phone: _phone }}
            functions={
                {
                    'Настройка ролей': openModal,
                }
            }
            colspanCount={9}
        />
    </div>
    <MyModal className="client__role-list" isOpenModal={isModalOpen} closeModal={closeModal} title={"Роли"}>
        <div className="client__role-list_content">
            <CheckboxBlock className="" data={roles} selectedItems={Object.keys(clientRoles).map(Number)} setSelectedItems={handleSetSelectedItems}/>
        </div>
    </MyModal>
  </>
}