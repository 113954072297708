import TypeConst from "./consts";

export default function todosReducer(state = {
    langs: {},
    clientRoles: {},
    permissions: {},
    roles: {},
    products: {},
    competitionProducts: {},
    productCategories: {},
    platforms: {},
    orderTypes: {},
    bannerTypes: {},
    bannerStatuses: {},
    paymentStatuses: {},
    calendarStatuses: {},
    promocodes: {},
    competitions: {},
    competitionTeamDisciplines: {},
    paymentStatusesCertificate: {},
    surveys: {},
    categoryProducts: {},
    bookingBalanceTypes: {},
}, action) {
    switch (action.type) {
        case TypeConst.SET_LANGS:
            return {
                ...state,
                langs: action.langs
            };
        case TypeConst.SET_CLIENT_ROLES:
            return {
                ...state,
                clientRoles: action.clientRoles
            };
        case TypeConst.SET_BOOKING_BALANCE_TYPES:
            return {
                ...state,
                bookingBalanceTypes: action.bookingBalanceTypes
            };
        case TypeConst.SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions
            };
        case TypeConst.SET_ROLES:
            return {
                ...state,
                roles: action.roles
            };
        case TypeConst.SET_PRODUCTS:
            return {
                ...state,
                products: action.products
            };
        case TypeConst.SET_CATEGORY_PRODUCTS:
            return {
                ...state,
                categoryProducts: action.categoryProducts
            };
        case TypeConst.SET_COMPETITION_PRODUCTS:
            return {
                ...state,
                competitionProducts: action.competitionProducts
            };
        case TypeConst.SET_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategories: action.productCategories
            };
        case TypeConst.SET_PLATFORMS:
            return {
                ...state,
                platforms: action.platforms
            };
        case TypeConst.SET_ORDER_TYPES:
            return {
                ...state,
                orderTypes: action.orderTypes
            };
        case TypeConst.SET_BANNER_TYPES:
            return {
                ...state,
                bannerTypes: action.bannerTypes
            };
        case TypeConst.SET_BANNER_STATUSES:
            return {
                ...state,
                bannerStatuses: action.bannerStatuses
            };
        case TypeConst.SET_PAYMENT_STATUSES:
            return {
                ...state,
                paymentStatuses: action.paymentStatuses
            };
        case TypeConst.SET_CALENDAR_STATUSES:
            return {
                ...state,
                calendarStatuses: action.calendarStatuses
            };
        case TypeConst.SET_PROMOCODES:
            return {
                ...state,
                promocodes: action.promocodes
            };
        case TypeConst.SET_COMPETITIONS:
            return {
                ...state,
                competitions: action.competitions
            };
        case TypeConst.SET_COMPETITION_TEAM_DISCIPLINES:
            return {
                ...state,
                competitionTeamDisciplines: action.competitionTeamDisciplines
            };
        case TypeConst.SET_PAYMENT_STATUSES_CERTIFICATE:
            return {
                ...state,
                paymentStatusesCertificate: action.paymentStatusesCertificate
            };
        case TypeConst.SET_SURVEYS:
            return {
                ...state,
                surveys: action.surveys
            };
        default:
            return state;
    }
}
