import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class CalendarDayFormatter extends Formatter{
    static CustomItem(data) {
        return data;
      }
}
export default class ApiDayCalendar extends ApiModelWithAuth{
    pathDef = 'authorized/admin/event/calendar/days';

    formatterItem = CalendarDayFormatter.CustomItem;
}