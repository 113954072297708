import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Хранилище административной панели
import {
        setPermissions, setRoles, setProducts, 
        setOrderTypes, setPlatforms,
        setProductCategories, setBannerTypes, setBannerStatuses,
        setCalendarStatuses, setPromocodes,
        setCompetitions, setCompetitionTeamDisciplines, 
        setPaymentStatusesCertificate, setCompetitionProducts,
        setSurveys, setCategoryProducts, setBookingBalanceTypes, 
        setClientRoles
    }  from "./app/reducers/admin/option/actions";
//Хранилище личного кабинета
import { 
    setDefaultBalances, setOrderProducts, 
    setOrderPromocodes, setUserCertificates, 
    setAvailableProducts 
} from "./app/reducers/account/option/actions"
import { 
    setPaymentStatuses, setPaymentTypes, 
    setBookingTypes, setBookingStatuses,
    setBookingTimeStatuses, setBookingTimeTypes
} from "./app/reducers/common/option/actions";
import DateLib from "./inc/DateLib";
import ApiAppInit from "./app/api/authorized/common/ApiAppInit";
import ApiAuth from "./app/api/public/system/ApiAuth";
export default function AppInit() {
  const dispatch = useDispatch();
  const expAccessToken = useSelector((state) => state.app.expAccessToken);
  const expRefreshToken = useSelector((state) => state.app.expRefreshToken);

  useLayoutEffect(()=>{
    const load = async () =>{
      let apiAppInit = ApiAppInit.Index({});
      let data = await apiAppInit.getResponse();
      if(!data) return;
      //Административная панель
      if('admin_permissions' in data) dispatch(setPermissions(data.admin_permissions));
      if('admin_roles' in data) dispatch(setRoles(data.admin_roles));
      if('admin_products' in data) dispatch(setProducts(data.admin_products));
      if('admin_competition_products' in data) dispatch(setCompetitionProducts(data.admin_competition_products));
      if('admin_product_categories') dispatch(setProductCategories(data.admin_product_categories));
      if('admin_platforms' in data) dispatch(setPlatforms(data.admin_platforms));
      if('admin_order_types' in data) dispatch(setOrderTypes(data.admin_order_types));
      if('admin_banner_types' in data) dispatch(setBannerTypes(data.admin_banner_types));
      if('admin_banner_statuses' in data) dispatch(setBannerStatuses(data.admin_banner_statuses));
      if('admin_calendar_statuses' in data) dispatch(setCalendarStatuses(data.admin_calendar_statuses));
      if('admin_promocodes' in data) dispatch(setPromocodes(data.admin_promocodes));
      if('admin_competitions' in data) dispatch(setCompetitions(data.admin_competitions));
      if('admin_competition_team_disciplines' in data) dispatch(setCompetitionTeamDisciplines(data.admin_competition_team_disciplines));
      if('admin_cetrificates' in data) dispatch(setPaymentStatusesCertificate(data.admin_cetrificates));
      if('admin_surveys' in data) dispatch(setSurveys(data.admin_surveys));
      if('admin_category_products' in data) dispatch(setCategoryProducts(data.admin_category_products));
      if('admin_booking_balance_types' in data) dispatch(setBookingBalanceTypes(data.admin_booking_balance_types));
      if('admin_client_roles' in data) dispatch(setClientRoles(data.admin_client_roles));
      //Личный кабинет
      if('account_available_products' in data) dispatch(setAvailableProducts(data.account_available_products));
      if('account_order_products' in data) dispatch(setOrderProducts(data.account_order_products));
      if('account_order_promocodes' in data) dispatch(setOrderPromocodes(data.account_order_promocodes));
      if('account_certificates' in data) dispatch(setUserCertificates(data.account_certificates));
      if('account_booking_balance_default' in data) dispatch(setDefaultBalances(data.account_booking_balance_default));
      //Общие опции
      if('common_payment_statuses' in data) dispatch(setPaymentStatuses(data.common_payment_statuses));
      if('common_payment_types' in data) dispatch(setPaymentTypes(data.common_payment_types));
      if('common_booking_types' in data) dispatch(setBookingTypes(data.common_booking_types));
      if('common_booking_statuses' in data) dispatch(setBookingStatuses(data.common_booking_statuses));
      if('common_booking_time_status' in data) dispatch(setBookingTimeStatuses(data.common_booking_time_status));
      if('common_booking_time_types' in data) dispatch(setBookingTimeTypes(data.common_booking_time_types));
    }
    load();
      // eslint-disable-next-line
  }, []);
  //refresh AccessToken
  useLayoutEffect(()=>{
    const refresh = () => {
      let milsec = parseInt(expRefreshToken - DateLib.now('unix'));
      if(milsec > 0) ApiAuth.RefreshToken();
      else ApiAuth.Logout();
    }
    let timer = null;
    let milsec = parseInt(expAccessToken - DateLib.now('unix') - 5000);
    if(milsec > 0){
      timer = setTimeout(() => {
        refresh();
      }, milsec);
    }else refresh();
    return () =>{
      if(timer) clearTimeout(timer);
    }
  }, [expAccessToken, expRefreshToken]);
  return null;
}
