//Реакт
import React, { useLayoutEffect, useContext, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiTimes from "../../../../../app/api/authorized/account/booking/ApiTimes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
import Datelib from "../../../../../inc/DateLib.js";
//Mui icons
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import NumbersIcon from '@mui/icons-material/Numbers';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import TollIcon from '@mui/icons-material/Toll';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
//Компоненты
import InputNumber from "../../../../components/inputs/number";
import Select from "../../../../components/inputs/select";
import InputDateTime from "../../../../components/inputs/datetime";
//Стили
import './styles.css';

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export default function AccountTimeIndex({ ...other }) {
    return <Page {...other}>
        <AccountTimeIndexContent {...other} />
    </Page>
}

function AccountTimeIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_balanceName, set_balanceName] = useState(null);
    
    const [_minutes, set_minutes] = useState("");
    const [_bookingTimeType, set_bookingTimeType] = useState("");
    const [_paymentType, set_paymentType] = useState("");
    const [_bookingTimeStatus, set_bookingTimeStatus] = useState("");
    const [_createdAt, set_createdAt] = useState("");

    const paymentTypes = useSelector(state => state.commonOption.paymentTypes);
    const bookingTimeTypes = useSelector(state => state.commonOption.bookingTimeTypes);
    const bookingTimeStatuses = useSelector(state => state.commonOption.bookingTimeStatuses);

    const handleBookingTimeTypeChange = (event) => {
      set_bookingTimeType(event.target.value);
    }

    const handlePaymentTypeChange = (event) => {
      set_paymentType(event.target.value);
    }

    const handleBookingTimeStatusChange = (event) => {
      set_bookingTimeStatus(event.target.value);
    } 

    const handleChangeCreatedAt = (event) => {
      set_createdAt(event.target.value);
    }

    const query = useQuery()

    useLayoutEffect(() => {
        contextPage.setTitle(`Транзакции по депозиту`);
        contextContent.setTitle(`Транзакции по депозиту`);
        contextPage.setName(_balanceName);
    }, [contextContent, contextPage,_balanceName]);

    const header = [
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            <div className="header-row__center">
                <TimelapseIcon/>
                <div className="header-row__center_text">Минуты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="user_balance_id"
            name="user_balance_id"
        >
            <div className="header-row__center">
                <FormatItalicIcon/>
                <div className="header-row__center_text">ID баланса</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="user_balance_type_name"
            name="user_balance_type_name"
        >
            <div className="header-row__center">
                <AccessTimeFilledIcon/>
                <div className="header-row__center_text">Депозит</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_name"
            name="type_name"
        >
            <div className="header-row__center">
                <WebStoriesIcon/>
                <div className="header-row__center_text">Тип операции</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_name"
            name="payment_type_name"
        >
            <div className="header-row__center">
                <TollIcon/>
                <div className="header-row__center_text">Тип оплаты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status_name"
            name="status_name"
        >
            <div className="header-row__center">
                <AssuredWorkloadIcon/>
                <div className="header-row__center_text">Статус оплаты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            <div className="header-row__center">
                <EventAvailableIcon/>
                <div className="header-row__center_text">Дата создания</div> 
            </div>
        </ListHeaderItem>,
    ];

    const filters = [
      <InputNumber className="input_wf" value={_minutes} onChange={(e) => { set_minutes(e.target.value) }}>Минуты</InputNumber>,
      <Select className="input_wf" options={bookingTimeTypes} value={_bookingTimeType} onChange={handleBookingTimeTypeChange}>Тип операции</Select>,
      <Select className="input_wf" options={paymentTypes} value={_paymentType} onChange={handlePaymentTypeChange}>Тип оплаты</Select>,
      <Select className="input_wf" options={bookingTimeStatuses} value={_bookingTimeStatus} onChange={handleBookingTimeStatusChange}>Статус оплаты</Select>,
      <InputDateTime 
        className="input_wf" 
        value={_createdAt}
        onChange={handleChangeCreatedAt}
      >
        Создан от
      </InputDateTime>
    ];

    const renderRow = (row, i) => {
        if(!_balanceName) set_balanceName(row?.user_balance_type_name)
        return [
            <ListBodyItem className="account-times_column-width" key="minutes">{row.minutes}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="user_balance_id">{row.user_balance_id}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="user_balance_type_name">{row.user_balance_type_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="type_name">{row.type_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="payment_type_name">{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="status_name">{row.status_name}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="id">{row.id}</ListBodyItem>,
            <ListBodyItem className="account-times_column-width" key="created_at">
              {Datelib.formatterDateTime(row.created_at, "ru")}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiTimes}
            renderRow={renderRow}
            searchParams={
              {
                balanceId: query.get('balanceId'),
                minutes: _minutes,
                bookingTimeType: _bookingTimeType,
                bookingTimeStatus: _bookingTimeStatus,
                paymentType: _paymentType,
                createdAt: _createdAt,
              }
            }
        />
    </>
}
