//React
import React from 'react';
//Компоненты
import Counter from '../../../../../components/counter';
import Products from '../../../../../components/products';
import Input from '../../../../../components/input';
import Button from '../../../../../../../components/button';
//Стили
import "./styles.css";
import "../../../styles.css";

export default function Card({
  selectedSlot,
  countPeople,
  handleCountChange,
  productTime,
  productType,
  minutesByMainProducts,
  minutesByAdditionalProducts,
  handleProductChange,
  promocodeErrors,
  promocodeString,
  setPromocodeString,
  promocodeLabel,
  handleBookByCard,
  children
}){
  return (
    <div className="step-buy__content-body">
      <div className="step-buy__content-row">
        <label className="step-buy__content-row_label">Человек</label>
        <Counter
          className="step-buy__content-row_item"
          countPeople={countPeople}
          onChange={handleCountChange}
          max={15}
        />
      </div>
      <div className="step-buy__content-row">
        <label className="step-buy__content-row_label">Время (мин)</label>
        <Products
          className="step-buy__content-row_item"
          selectedSlot={selectedSlot}
          selectedProduct={productTime}
          products={productType === 'main' ? minutesByMainProducts : minutesByAdditionalProducts}
          countPeople={countPeople}
          onChange={handleProductChange}
        />
      </div>
      {children}
      <div className="step-buy__content-row">
        <Input
          errors={promocodeErrors}
          value={promocodeString}
          onChange={(e) => setPromocodeString(e.target.value)}
          className={`step-buy__content-row_item--last`}
        >
          Промокод
        </Input>
        {promocodeLabel && promocodeString && (
          <div className="step-buy__content-row_success-label">{promocodeLabel}</div>
        )}
      </div>
      <Button className="step-buy__content-button" onClick={handleBookByCard}>
        Оформить
      </Button>
    </div>
  );
};
