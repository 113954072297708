//Реакт
import React from 'react';
//Mui Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
//Стили
import './styles.css';

export default function BalanceHeader({ step, prevStep }) {
    return (
        <>
            <div className='balance-header__content'>
                <ArrowBackIcon className={`${step > 1 && step < 3 ? '' : 'balance-header__item--hide'}`} onClick={prevStep} /> 
                <h3 className="balance-header__label">Пополнение депозита</h3>
                <CloseIcon className='balance-header__item--hide'/> 
            </div>
        </>
    );
}