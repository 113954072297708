//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
//Библиотеки и Api
import ApiTeamCompetition from "../../../../../app/api/authorized/admin/event/ApiTeamCompetition.js";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputPhone from "../../../../components/inputs/phone/phone";
import InputWrapper from "../../../../components/input/__wrapper";
//Стили
import './styles.css';
import '../../../styles.css';


export default function CompetitionTeamShow({ ...other }) {
    return <Page {...other} ApiModel={ApiTeamCompetition}>
        <CompetitionTeamShowContent {...other} />
    </Page>
}

function CompetitionTeamShowContent({ ...other }) {
    const contextPage = useContext(ContextPage);
    const contextContent = useContext(ContextContent);

    const [_name, set_name] = useState('');
    const [_city, set_city] = useState('');
    const [_competition, set_competition] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_discipline, set_discipline] = useState('');
    const [_users, set_users] = useState([]);

    //костыль, чтобы спрятать кнопку редактирования
    const headerRight = document.querySelector('.content__header-rigth');
    if (headerRight) {
        headerRight.style.display = 'none';
    }
    

    useLayoutEffect(() => {
        contextContent._handleSetData.current = (data) => {
            set_users(data.users);
            set_name(data.name);
            set_competition(data.competition);
            set_phone(data.phone);
            set_email(data.email);
            set_city(data.city)
            set_discipline(data.descipline_name);
            contextPage.setTitle(`Команда: ${data.name}`);
            contextContent.setTitle(`Информация о команде`);
            contextPage.setName(data.name);
        };
        contextContent.doLoad();
    }, []);

    const renderUsers = () => (
        <>
            <h2>Список участников</h2>
            {
                Array.isArray(_users) && _users.map((user, index) => (
                    <React.Fragment key={index}>
                        <InputWrapper>
                            <InputText className="pages__show-input" value={user.name} readOnly>Имя</InputText>
                        </InputWrapper>
                        <InputWrapper>
                            <InputText className="pages__show-input" value={user.phone} readOnly>Телефон</InputText>
                        </InputWrapper>
                        <InputWrapper>
                            <InputText className="pages__show-input" value={user.tshirt_size} readOnly>Размер</InputText>
                        </InputWrapper>
                        {index < _users.length - 1 && <hr />}
                    </React.Fragment>
                ))
            }
        </>
    );

    return <>
        <InputWrapper>
            <InputText className="pages__show-input" value={_name} readOnly>Название команды</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_city} readOnly>Город</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_competition} readOnly>Соревнование</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_discipline} readOnly>Дисциплина</InputText>
        </InputWrapper>
        <InputWrapper>
            <InputPhone className="pages__show-input" value={_phone} readOnly>Телефон</InputPhone>
        </InputWrapper>
        <InputWrapper>
            <InputText className="pages__show-input" value={_email} readOnly>Почта</InputText>
        </InputWrapper>
        {renderUsers()}
    </>
}
