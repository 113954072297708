import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class SurveyFormatter extends Formatter{}
export default class ApiClientSurvey extends ApiModelWithAuth{
    pathDef = 'authorized/admin/management/surveys';

    static async GetUsers(params){
        const api = new this({});
        api.getUsersWithEmailOrPhone(params);
        let rdata = await api.getResponse();
        if(rdata){
            const data = rdata.response
            return data;
        }
        return null;
    }

    static async GetSurvey(params){
        const api = new this({});
        api.getSurvey(params);
        let rdata = await api.getResponse();
        if(rdata){
            const data = rdata.response
            return data;
        }
        return null;
    }

    static async GetChildSurvey(params){
        const api = new this({});
        api.getChildSurvey(params);
        let rdata = await api.getResponse();
        if(rdata){
            const data = rdata.response
            return data;
        }
        return null;
    }

    getSurvey(params){
        this.__type = "GET";
        this.__path = 'authorized/admin/management/surveys/getSurvey';
        this.__params = params;
    }

    getChildSurvey(params){
        this.__type = "GET";
        this.__path = 'authorized/admin/management/surveys/getChildSurvey';
        this.__params = params;
    }

    getUsersWithEmailOrPhone(params){
        this.__type = "GET";
        this.__path = 'authorized/admin/management/surveys/getUsersWithEmailOrPhone';
        this.__params = params;
    }

    formatterItem = SurveyFormatter.Item;
}

