//Реакт
import React, { useLayoutEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Библиотеки и Api
import ApiInit from "./app/models/ApiInit";
//Модули Content
import { ContextApp } from "./view/modules/app/appContext";
//Системные копоненты
import InitApp from "./inc/InitApp";
import AppInit from "./AppInit";
import AppMdl from "./view/modules/app";
//Страницы
import Pages from "./view/pages/index-auth";
import PublicPages from "./view/pages/index-public";
//import Messages from "./view/modules/messages";
//Стили
import "./styles-vars.css";

const setTitle = (title) => {
    let _title = document.head.querySelector('title');
    if (_title) _title.textContent = title ? `${title} - FlyStation` : 'FlyStation';
}
function Title() {
    const title = useSelector(state => state.app.title);
    useLayoutEffect(() => {
        setTitle(title);
    }, [title])
    return null;
}

export default function App() {
    return <AppMdl>
        <AppContent />
    </AppMdl>
}
function AppContent() {
    const dispatch = useDispatch();
    const contextApp = useContext(ContextApp);
    const isAuth = useSelector((state) => state.app.isAuth);
    const [isLoaded, setIsLoaded] = useState(false);
    useLayoutEffect(() => {
        ApiInit.contextApp = contextApp;
    }, [contextApp]);
    useLayoutEffect(() => {
        const initData = async () => {
            const authData = await InitApp.authDataFromStorageLocal();
            if (authData) InitApp.setData({ dispatch, ...authData });
            setIsLoaded(true);
        }
        initData();
    }, [dispatch]);
    if (!isLoaded) return null;
    if (!isAuth) {
        return <>
            <PublicPages/>
        </>
    }
    return <>
        <Title />
        <AppInit />
        <Pages />
    </>
}
