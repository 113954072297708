import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ContextBooking } from './bookingContext';
import ApiBookingProducts from '../../../app/api/public/products/ApiBookingProducts';
import ApiSlotTimes from '../../../app/api/public/booking/slotTimes/ApiSlotTimes';
import ApiSlotTimesAuth from '../../../app/api/authorized/account/slotTimes/ApiSlotTimes'
import ApiPromocodes from '../../../app/api/public/booking/promocode/ApiPromocodes';
import ApiCertificateCheck from '../../../app/api/public/certificate/ApiCertificateCheck';
import ApiBalances from '../../../app/api/authorized/account/booking/ApiBalances';
import ApiBalanceProducts from '../../../app/api/authorized/account/booking/ApiBalanceProducts';
import ApiProfile from '../../../app/api/authorized/common/ApiProfile';

export function ProviderBooking({ children }) {
    const isAuth = useSelector((state) => state.app.isAuth);
    const [bookingData, setBookingData] = useState({
        products: [],
        balanceProducts: [],
        productTime: null,
        balances: [],
        certificate: {
            certificateString: null,
            certificateMinutes: null,
            certificateErrors: {},
        },
        promocode: {
            promocodeString: null,
            promocodeErrors: {},
            promocodeLabel: '',
            promocodeDiscount: null,
        },
        user: {
            name: null,
            email: null,
            phone: null,
        },
        
        slotTime: {},
        isLoadingSlots: false,
        timeId: null,
        useCertificate: false,
        countPeople: 1,
        productId: null,
        balanceId: null,
        selectedDate: null,
    });

    useEffect(() => {
        
        const fetchProducts = async () => {
          const apiBookingProducts = ApiBookingProducts.Index();
          const responseData = await apiBookingProducts.getResponse();
                
          if(responseData) {
            const products = responseData.map(product => ({
              id: product.id,
              name: product.name,
              code: product.code,
              price: product.price,
              old_price: product.old_price,
              minutes: product.minutes
            }));
        
            setBookingData(prevData => ({
              ...prevData,
              products
              }));
            }
        };
    
        const fetchBalanceProducts= async () => {
          const apiBalanceProducts= ApiBalanceProducts.Index();
          const responseData = await apiBalanceProducts.getResponse();

          if(responseData) {
            const balanceProducts = responseData.products ?? [];
            setBookingData(prevData => ({
              ...prevData,
              balanceProducts
            }));
          }  else {
            throw new Error('Ошибка при получении продуктов');
          }
        };

        const fetchUserData= async () => {
          const apiUserData= ApiProfile.Show();
          const responseData = await apiUserData.getResponse();

          if(responseData) {
            const email = responseData.email ?? '';
            const name = responseData.name ?? '';
            const phone = responseData.phone ?? '';
                
            setBookingData(prevData => ({
              ...prevData,
              user: {
                name: name,
                email: email,
                phone: phone
              }
            }));
          }  
        };

  const fetchData = async () => {
    try {
            if (bookingData.products.length === 0) {
              await fetchProducts();
            }
      
            if (isAuth) {
              if (bookingData.balances.length === 0) {
                try {
                  await fetchBalanceProducts();
                } catch (error) { return; }
                try {
                  await fetchBalances(); 
                } catch (error) { return; }
              }
              try {
                await fetchUserData();
              } catch (error) { return }
            }
          } catch (error) {}
        };
        
        fetchData();

    }, []);

  const fetchBalances= async () => {
    const apiBalances= ApiBalances.Index();
    const responseData = await apiBalances.getResponse();
        
    if(responseData) {
      const balances = responseData.map(balance => ({
        id: balance.id,
        balance: balance.balance,
        reserved: balance.reserved,
        type_name: balance.type_name,
        type_id: balance.type_id
      }));

      setBookingData(prevData => ({
        ...prevData,
        balances
      }));
    
    } else {
      throw new Error('Ошибка при получении балансов');
    }
  };

    const fetchSlotTime = async (date, showAll) => {
      setBookingData(prevData => ({
        ...prevData,
        isLoadingSlots: true,
        slotTime: {}
      }));
      try {
        const apiSlotTimes = showAll ?  ApiSlotTimesAuth.Index({ date }) : ApiSlotTimes.Index({ date });
        const slotTime = await apiSlotTimes.getResponse({});
      
        setBookingData(prevData => ({
          ...prevData,
          slotTime,
          isLoadingSlots: false
        }));
        return slotTime;
      } catch (error) {
        setBookingData(prevData => ({
        ...prevData,
        isLoadingSlots: false
      }));
      }
    };

  const fetchPromocodeCheck = async (promocode, productId) => {
    if (promocode && promocode.length > 2 && productId) {
      try {
        const apiPromocodes = ApiPromocodes.Store({ promocode, productId });
        const res = await apiPromocodes.getResponse();
        setBookingData(prevData => ({
          ...prevData,
          promocode: {
            ...prevData.promocode,
            promocodeErrors: res ? {} : { promocode: apiPromocodes.errors },
            promocodeLabel: res ? res.label : '',
            promocodeDiscount: res ? res.discount : null,
          }
        }));
      } catch (error) {
        setBookingData(prevData => ({
          ...prevData,
          promocode: {
            ...prevData.promocode,
            promocodeErrors: {},
            promocodeLabel: '',
            promocodeDiscount: null,
          }
        }));
      }
    } else {
      setBookingData(prevData => ({
        ...prevData,
        promocode: {
          ...prevData.promocode,
          promocodeErrors: {},
          promocodeLabel: '',
          promocodeDiscount: null,

        }
      }));
    }
  };

  const fetchCertificateCheck = async (code) => {
    try {
      const apiCertificateCheck = ApiCertificateCheck.Store({ code });
      const res = await apiCertificateCheck.getResponse();
      
      setBookingData(prevData => ({
        ...prevData,
        certificate: {
          certificateErrors: res ? {} : { certificate: apiCertificateCheck.errors },
          certificateString: res ? res.code : null,
          certificateMinutes: res ? res.minutes : null
        }
      }));
    } catch (error) {
      setBookingData(prevData => ({
        ...prevData,
        certificate: {
          certificateErrors: {},
          certificateString: null,
          certificateMinutes: null
        }
      }));
    }
  };

  const resetPromocodeFields = () => {
    setBookingData(prevData => ({
      ...prevData,
      promocode: {
        promocodeErrors: {},
        promocodeLabel: '',
        promocodeDiscount: null,
      }
    }));
  };

  const resetFields = () => {
    setBookingData(prevData => ({
        ...prevData,
        productTime: null,
        slotTime: {},
        timeId: null,
        productId: null,
        balanceId: null,
        selectedDate: null,
        certificate: {
            certificateString: null,
            certificateMinutes: null,
            certificateErrors: {},
        },
      }));
  }

  const setTimeId = (timeId) => {
    setBookingData(prevData => ({ ...prevData, timeId }));
  };

  const setProductTime = (productTime) => {
    setBookingData(prevData => ({ ...prevData, productTime }));
  };

  const setUseCertificate = (useCertificate) => {
    setBookingData(prevData => ({ ...prevData, useCertificate }));
  };

  const setCountPeople = (countPeople) => {
    setBookingData(prevData => ({ ...prevData, countPeople }));
  };

  const setPromocodeString = (promocodeString) => {
    setBookingData(prevData => ({ ...prevData, promocode: {promocodeString: promocodeString} }));
  };

  const setCertificateString = (certificateString) => {
    setBookingData(prevData => ({ ...prevData, certificate: {certificateString: certificateString} }));
  };

  const setProductId = (productId) => {
    setBookingData(prevData => ({ ...prevData, productId }));
  };

  const setBalanceId = (balanceId) => {
    setBookingData(prevData => ({ ...prevData, balanceId }));
  };

  const setSelectedDate = (selectedDate) => {
    setBookingData(prevData => ({ ...prevData, selectedDate }));
  };

  const setUser = (updatedUser) => {
    setBookingData((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        ...updatedUser
      }
    }));
  };
      
  const setName = (name) => {
    setUser({ name });
  };
      
  const setEmail = (email) => {
    setUser({ email });
  };
      
  const setPhone = (phone) => {
    setUser({ phone });
  };

  return (
    <ContextBooking.Provider value={{
      isAuth, 
      bookingData, 
      resetFields,
      setBookingData,
      resetPromocodeFields,
      fetchSlotTime,
      fetchPromocodeCheck,
      fetchCertificateCheck,
      fetchBalances,
      setPromocodeString,
      setCertificateString,
      setTimeId,
      setUseCertificate,
      setCountPeople,
      setProductId,
      setBalanceId,
      setSelectedDate,
      setProductTime,
      setName,
      setEmail,
      setPhone
    }}>
      {children}
    </ContextBooking.Provider>
  );
}
