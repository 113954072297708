//Реакт
import React, { useState } from 'react';
import { ProviderBooking } from './bookingProvider';
import { useBookingContext } from './bookingContext';
//Библиотеки и Api
//компоненты
import StepBuy from './steps/buy/step-buy';
import StepPreview from './steps/preview/step-preview';
import StepAfterpay from './steps/afterpay/step-afterpay';
import BookingHeader from './components/booking-header/booking-header';
//Стили
import './styles.css'

export default function BookingWithProvider({children, className='', apiBookingByCard = null, apiBookingByCertificate = null, apiBookingByBalance = null, ...other}) {
    return (
        <ProviderBooking {...other}>
            {children}
            <div className={`${className}`}>
                <BookingComponent 
                    apiBookingByCard={apiBookingByCard} 
                    apiBookingByCertificate={apiBookingByCertificate} 
                    apiBookingByBalance={apiBookingByBalance}
                />
            </div>
        </ProviderBooking>
    );
}

function BookingComponent({className = '', apiBookingByCard, apiBookingByCertificate, apiBookingByBalance}) {
    const [step, setStep] = useState(1);
    const [_previewData, set_previewDara] = useState(null)
    const { bookingData , fetchBalances, resetFields} = useBookingContext();

    const { promocode, balanceId, productTime, certificate, timeId, countPeople, productId, selectedDate, user} = bookingData;
    const { promocodeString } = promocode;
    const { certificateString } = certificate;
    const { name, email, phone } = user

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const goToFirst = () => {
        setStep(1)
    };

    const handleBookByBalance = () => {
        const fetchBookingByBalance = async () => {
            let apiBookings = apiBookingByBalance.Store({
               name: name, email: email, phone: phone, date: selectedDate, timeId: timeId, certificate: certificateString, 
               minutes: productTime, balanceId: balanceId
            });
        
            const bookingResult = await apiBookings.getResponse({});
            if(bookingResult) {
                setStep(3);
                resetFields();
                fetchBalances()
            }
        };
        if(apiBookingByBalance) fetchBookingByBalance();
    }

    const handleBookByCard = () => {
        const fetchBookingByCard = async () => {
            let apiBookings = apiBookingByCard.Store({
                name: name, email: email, phone: phone, productId: productId, 
                date: selectedDate, timeId: timeId, countPeople: countPeople, promocode: promocodeString
            });
        
            const bookingResult = await apiBookings.getResponse({});
            if(bookingResult){
                set_previewDara(bookingResult)
                resetFields();
                nextStep();
            } 
        };
        if(apiBookingByCard) fetchBookingByCard();
    }

    const handleBookByCertificate = () => {
        const fetchBookingByCertificate = async () => {
            let apiBookings = apiBookingByCertificate.Store({
                name: name, email: email, phone: phone, date: selectedDate, timeId: timeId, certificate: certificateString
            });
        
            const bookingResult = await apiBookings.getResponse({});
            if(bookingResult) {
                resetFields();
                setStep(3);
            } 
        };
        if(apiBookingByCertificate) fetchBookingByCertificate();
    }
    

    const steps = [
        <StepBuy 
            nextStep={nextStep} 
            handleBookByBalance={handleBookByBalance} 
            handleBookByCard={handleBookByCard} 
            handleBookByCertificate={handleBookByCertificate}
            hasBookByCard={apiBookingByCard ? true : false}
            hasBookByBalance={apiBookingByBalance ? true : false}
            hasBookByCertificate={apiBookingByCertificate ? true : false}
        />,
        <StepPreview nextStep={nextStep} prevStep={prevStep} previewData={_previewData}/>,
        <StepAfterpay prevStep={prevStep}  goToFirst={goToFirst}/>
    ];

    return (
        <>
            <div className={`booking__card ${className}`}>
                <div className="booking__card-header">
                    <BookingHeader prevStep={prevStep} step={step}/>
                </div>
                <div className="booking__card-body">
                    {steps[step - 1] || <>Error: Unknown step</>}
                </div>
            </div>
        </>
    );
}