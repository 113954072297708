//Реакт
import React, { useState } from "react";
//Библиотеки  и Api
import ApiBookingByCard from "../../../../app/api/public/booking/booking/ApiBookingByCard"
import ApiBookingByCertificate from "../../../../app/api/public/booking/booking/ApiBookingByCertificate"
//Модули Layout
import LayoutAuth from "../../../modules/layouts/auth";
import BookingComponent from "../../../modules/booking/booking";
//Стили
import './styles.css';
import '../../styles.css';

export default function BookingPage(){
  return (
      <LayoutAuth className="layout-auth">
        <BookingPageContent/>
      </LayoutAuth>
  );
};
function BookingPageContent(){
  return (
    <div>
      <BookingComponent
        apiBookingByCard={ApiBookingByCard}
        apiBookingByCertificate={ApiBookingByCertificate}
        className="booking__card-modal"
      />
    </div>
  );
};
