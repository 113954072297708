// Реакт
import React, { useState, useEffect } from 'react';
// Redux
import { useSelector } from "react-redux";
// Api и библтотеки
import ApiDayCalendar from '../../../app/api/authorized/admin/event/ApiCalendarDay';
import ApiDaySaveCalendar from '../../../app/api/authorized/admin/event/ApiCalendarDaySave';
import ApiDayClearCategoryCalendar from '../../../app/api/authorized/admin/event/ApiCalendarDayClearCategory';
// Компоненты
import DayItem from './components/day-items';
import ContentGroupBox from '../../components/contentGroupBox/contentGroupBox';
import Modal from '../../components/modals/modal';
import DayParams from './components/day-params/day-param';
import ModalYesNo from '../../components/modals/modal-yes-no/modal-yes-no';
import Select from '../../components/inputs/select';
import InputNumber from '../../components/inputs/number/number';
//MUI icons
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RefreshIcon from '@mui/icons-material/Refresh';
// Стили
import './styles.css';

//--------------------------------------------------------------------------------------------
// !!! ФУНКЦИИ ДЛЯ ОТОБРАЖЕНИЯ ДАННЫХ КАЛЕНДАРЯ !!!
//--------------------------------------------------------------------------------------------
// Подсчет дней в месяце
//--------------------------------------------------------------------------------------------
const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};
//-----------------------------------------------------------------------------------------------------
// Получение первого дня текущего месяца
// * в следствие используемого формата вывода могут выводится дни из предыдущих или последующих месяцев
//-----------------------------------------------------------------------------------------------------
const getFirstDayOfMonth = (year, month) => {
    return (new Date(year, month, 1).getDay() + 6) % 7;
};

//--------------------------------------------------------------------------------------------
// Подсчет дней в месяце
//--------------------------------------------------------------------------------------------
const getTotalDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

//--------------------------------------------------------------------------------------------
// Подсчет недель в месяце
//--------------------------------------------------------------------------------------------
const getWeeksInMonth = (year, month, firstDayOfMonth, totalDaysInMonth) => {
    const daysInMonth = firstDayOfMonth + totalDaysInMonth;
    return Math.ceil(daysInMonth / 7);
};

//--------------------------------------------------------------------------------------------
// Заполнение дней недель данными о днях
//--------------------------------------------------------------------------------------------
const getWeekData = (year, month, weekIndex, firstDayOfMonth, totalDaysInMonth, totalDaysInPrevMonth) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
        const dayIndex = weekIndex * 7 + i;
        const day = dayIndex - firstDayOfMonth + 1;
        const isWeekend = i === 5 || i === 6;
        let isDisabled = day <= 0 || day > totalDaysInMonth;
        const dayStatus = isWeekend ? 'weekend' : 'workday';
        const prevMonth = month === 0 ? 11 : Number(month) - 1;
        const nextMonth = month === 11 ? 0 : Number(month) + 1;
        const prevYear = month === 0 ? Number(year) - 1 : year;
        const nextYear = month === 11 ? Number(year) + 1 : year;
        const monthValue = isDisabled ? (day <= 0 ? prevMonth : nextMonth) : month;
        const yearValue = isDisabled ? (day <= 0 ? prevYear : nextYear) : year;
        const dayValue = isDisabled ? (day <= 0 ? totalDaysInPrevMonth + day : day - totalDaysInMonth) : day;
        const date = new Date(yearValue, monthValue, dayValue);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        isDisabled = date < currentDate ?? isDisabled;
        const dayName = date.toLocaleDateString('ru-RU', { weekday: 'long' });
        const dayData = {
            day: formatNumber(dayValue),
            month: formatNumber(Number(monthValue) + 1),
            year: yearValue,
            disabled: isDisabled,
            dayStatus: dayStatus,
            events: [],
            date: date,
            dayName: dayName
        };
        week.push(dayData);
    }
    return week;
};

//--------------------------------------------------------------------------------------------
// Заполнение месяцев данными о неделях
//--------------------------------------------------------------------------------------------
const getMonthData = (year, month) => {
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const totalDaysInMonth = getTotalDaysInMonth(year, month);
    const totalDaysInPrevMonth = getTotalDaysInMonth(year, month - 1);
    const weeksInMonth = getWeeksInMonth(year, month, firstDayOfMonth, totalDaysInMonth);
    let data = [];
    for (let i = 0; i < weeksInMonth; i++) {
        const weekData = getWeekData(year, month, i, firstDayOfMonth, totalDaysInMonth, totalDaysInPrevMonth);
        data.push(weekData);
    }
    return data;
};


//--------------------------------------------------------------------------------------------
// Форматирование дней и месяцев 
// * при расчетах получается число, однако в случае, если число одноразрядное мы хотим видеть
// перед ним 0
//--------------------------------------------------------------------------------------------
const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num;
};

//--------------------------------------------------------------------------------------------
// Парсинг информации о дате
//--------------------------------------------------------------------------------------------
const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('.').map(Number);
    return { day, month: month - 1, year }; // month: month - 1 because month is 0-indexed
};

//--------------------------------------------------------------------------------------------
// Массив для перевода названий месяцев на русский
//--------------------------------------------------------------------------------------------
const monthNames = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

//--------------------------------------------------------------------------------------------
// КОМПОНЕНТ - "КАЛЕНДАРЬ"
//--------------------------------------------------------------------------------------------
export default function Calendar({ onSelect, className = '' }) {

    //--------------------------------------------------------------------------------------------
    // Параметры календаря (отображение блоков дней и самих дат)
    //--------------------------------------------------------------------------------------------
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
    const [monthData, setMonthData] = useState(getMonthData(currentYear, currentMonth));

    //--------------------------------------------------------------------------------------------
    // Параметры взаимодействия с днями
    //--------------------------------------------------------------------------------------------
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCalendarStatus, setSelectedCalendarStatus] = useState(null);
    const [listIsOpen, setListIsOpen] = useState(false);
    const [listDateIsOpen, setListDateIsOpen] = useState(false);
    const [viewStatusChanger, setViewStatusChanger] = useState(false);
    const [isModalConfirm, setIsModalConfirm] = useState(false);

    //--------------------------------------------------------------------------------------------
    // Параметры точечного взаимодействия
    //--------------------------------------------------------------------------------------------
    const [isModalDay, setIsModalDay] = useState(false);

    //--------------------------------------------------------------------------------------------
    // Общие исходные данные для взаимодействия
    //--------------------------------------------------------------------------------------------
    const calendarStatuses = useSelector(state => state.adminOption.calendarStatuses);
    const productCategories = useSelector(state => state.adminOption.productCategories);

    //-------------------------------------------------------------------------------------------------------
    // !!! ФУНКЦИИ ВЗАИМОДЕЙСТВИЯ С ОТОБРАЖЕНИЕМ КАЛЕНДАРЯ !!!
    //-------------------------------------------------------------------------------------------------------
    // Обработка перехода к предыдущему месяцу
    //-------------------------------------------------------------------------------------------------------
    const handlePrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(Number(currentYear) - 1);
        } else {
            setCurrentMonth(Number(currentMonth) - 1);
        }
    };

    //-------------------------------------------------------------------------------------------------------
    // Выставление текущего месяца
    //-------------------------------------------------------------------------------------------------------
    const resetDate = () => {
        setCurrentMonth(new Date().getMonth() );
        setCurrentYear(new Date().getFullYear());
    }

    //-------------------------------------------------------------------------------------------------------
    // Обработка перехода к следующему месяцу
    //-------------------------------------------------------------------------------------------------------
    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(Number(currentYear) + 1);
        } else {
            setCurrentMonth(Number(currentMonth) + 1);
        }
    };

    //-------------------------------------------------------------------------------------------------------
    // Запрос и запись данных с сервера о просматриваемых датах
    //-------------------------------------------------------------------------------------------------------
    const fetchMonthData = async (monthData) => {
        try {
            const flattenedMonthData = monthData.flat();
            const firstDay = flattenedMonthData[0];
            const lastDay = flattenedMonthData[flattenedMonthData.length - 1];

            const apiCalendarDay = ApiDayCalendar.Store({
                date_start: firstDay.year + '-' + firstDay.month + '-' + firstDay.day,
                date_end: lastDay.year + '-' + lastDay.month + '-' + lastDay.day,
            });
            const responseData = await apiCalendarDay.getResponse();

            // Обновляем свойства дней в массиве monthData
            const updatedMonthData = monthData.map(week => {
                return week.map(day => {
                    const dayToUpdate = responseData.find(data =>
                        data.date == day.year + '-' + day.month + '-' + day.day
                    );
                    if (dayToUpdate) {
                        return {
                            ...day,
                            events: dayToUpdate.events,
                            dayStatus: dayToUpdate.dayStatus
                        };
                    } else {
                        return day;
                    }
                });
            });

            setMonthData(updatedMonthData);

        } catch (error) {
            console.error('Ошибка при запросе данных:', error);
        }
    };

    //-------------------------------------------------------------------------------------------------------
    // Прослушивание месяца и года и повторный запрос данных при их изменении
    //-------------------------------------------------------------------------------------------------------
    useEffect(() => {
        // При изменении месяца или года обновляем данные через API
        fetchMonthData(getMonthData(currentYear, currentMonth));

    }, [currentYear, currentMonth]);

    //--------------------------------------------------------------------------------------------
    // !!! ФУНКЦИИ ВЗАИМОДЕЙСТВИЯ С ДАТАМИ !!!
    //--------------------------------------------------------------------------------------------
    // Установка статуса выбранной группы дней (Выходной, Рабочий день, Закрыто ...)
    //--------------------------------------------------------------------------------------------
    const handleCalendarStatusChange = (event) => {
        setSelectedCalendarStatus(event.target.value);
    };

    //--------------------------------------------------------------------------------------------
    // Выставление параметра is_active категорий продуктов на определенную группу дней
    //--------------------------------------------------------------------------------------------
    const handleChangeModsOnCategory = (item) => {
        setSelectedCategories(prevCategories =>
            prevCategories.map(cat =>
                cat.id === item.id ? { ...cat, is_active: !cat.is_active } : cat
            )
        );
    };

    //-------------------------------------------------------------------------------------------------------
    // Добавление категорий продуктов в список определяющий поведение этих категорий на заданную выборку дат
    //-------------------------------------------------------------------------------------------------------
    const addSelectedCategory = (data) => {
        if (data) {
            // Проверяем, не дублируется ли элемент в списке
            if (!selectedCategories.some(selectedItem => selectedItem.id === data)) {
                setSelectedCategories([...selectedCategories, { id: data, name: productCategories[data], is_active: false }]);
            }
        }
    }

    //-------------------------------------------------------------------------------------------------------
    //  Удаление категорий продуктов из списка определяющего поведение этих категорий на заданную выборку дат
    //-------------------------------------------------------------------------------------------------------
    const deleteSelectedCategory = (data) => {
        if (data) {
            setSelectedCategories(selectedCategories.filter(selectedItem => selectedItem.id !== data));
        }
    }

    //-------------------------------------------------------------------------------------------------------------
    // Обработка нажатия на определенную дату внутри календаря (добавление в список дат группового взаимодействия)
    //-------------------------------------------------------------------------------------------------------------
    const handleDateSelection = (date) => {
        if (!date.disabled) {
            const index = selectedDates.findIndex((selectedDate) =>
                selectedDate.day === date.day &&
                selectedDate.month === date.month &&
                selectedDate.year === date.year
            );
            if (index !== -1) {
                setSelectedDates(selectedDates.filter((selectedDate) =>
                    selectedDate.day !== date.day ||
                    selectedDate.month !== date.month ||
                    selectedDate.year !== date.year
                ));
            } else {
                setSelectedDates([...selectedDates, date]);
            }

            if (onSelect) {
                const formattedDates = selectedDates.map((selectedDate) => {
                    const formattedDay = formatNumber(selectedDate.day);
                    const formattedMonth = formatNumber(Number(selectedDate.month) + 1);
                    return `${formattedDay}.${formattedMonth}.${selectedDate.year}`;
                });
                onSelect(formattedDates.join(', '));
            }
        }
    };

    //-------------------------------------------------------------------------------------------------------
    // Отправка данных группового взаимодействия (Обработка запроса отправки и подготовка к дальнейшей работе)
    //-------------------------------------------------------------------------------------------------------
    const handleSetDayParams = () => {
        //Тут должен быть api запрос с указанием дат, статусв им присвоенного и ивентами (три отдельных поля)
        createDayData()
        resetData()
        fetchMonthData(getMonthData(currentYear, currentMonth));
    }

    //-------------------------------------------------------------------------------------------------------
    // Запрос на очистку категорий
    //-------------------------------------------------------------------------------------------------------
    const handleClearCategoryDayParams = () => {

        createDayData()
        resetData()
        fetchMonthData(getMonthData(currentYear, currentMonth));
    }

    //-------------------------------------------------------------------------------------------------------
    // Сброс данных группового взаимодействия
    //-------------------------------------------------------------------------------------------------------
    const resetData = () => {
        setSelectedDates([]);
        setSelectedCategories([])
        setSelectedCalendarStatus(null);
        setListIsOpen(true)
        setIsModalDay(false)
    }

    //--------------------------------------------------------------------------------------------
    // Открытие модального окна для взаимодействия с конкретной датой
    //--------------------------------------------------------------------------------------------
    const handleOpenDayModal = (day) => {
        resetData()
        setTimeout(() => {
            setSelectedDates([day]); // Устанавливаем новое значение
            setSelectedCategories(day.events)
            setIsModalDay(true);
        }, 0);
    }
    const handleCloseDayModal = () => {
        resetData()
    }

    //--------------------------------------------------------------------------------------------
    // Запрос на сохранение данных
    //--------------------------------------------------------------------------------------------
    const createDayData = async () => {
        try {
            const apiCalendarDay = ApiDaySaveCalendar.Store({ selectedDates, selectedCalendarStatus, selectedCategories });
            const responseData = await apiCalendarDay.getResponse();
        } catch (error) {
            console.error('Ошибка при запросе данных:', error);
        }
    };

    //--------------------------------------------------------------------------------------------
    // Запрос на удаление категорий
    //--------------------------------------------------------------------------------------------
    const clearCategoryDayData = async () => {
        try {
            const apiCalendarClearCategoryDay = ApiDayClearCategoryCalendar.Store({ selectedDates });
            const responseData = await apiCalendarClearCategoryDay.getResponse();
        } catch (error) {
            console.error('Ошибка при запросе данных:', error);
        }
    };

    return (
        <div className={`calendar ${className}`}>
            <div className="calendar__header">
                <button className="calendar__header-button" onClick={handlePrevMonth}>&#x25C4;</button>
                <div className='calendar__header_center'>
                    <span className="calendar__header_center-date">
                        <Select className="calendar__header_center-date-month" options={monthNames} value={currentMonth} onChange={(e) => {
                            setCurrentMonth(Number(e.target.value))
                        }}/>
                        <InputNumber className='calendar__header_center-date-year' hideArrows={true} value={currentYear} onChange={(e) => {setCurrentYear(e.target.value)}}/>
                        <RefreshIcon onClick={resetDate} className="calendar__header_center-date--reset-button" style={{fill: 'rgb(25, 118, 210)' }}/>
                    </span>
                    <ContentGroupBox toggle={true} changeStatus={viewStatusChanger} className={`calendar-transition ${selectedDates.length > 0 ? 'contentGroupBox--active' : 'contentGroupBox--inactive'}`} icon={<EditCalendarIcon className={`${selectedDates.length > 0 ? 'contentGroupBox__calendar-icon--active' : 'contentGroupBox__calendar-icon--inactive'}`} />}>
                        <DayParams
                            selectedDates={selectedDates.map((selectedDate, index) => {
                                const formattedMonth = formatNumber(Number(selectedDate.month) );
                                return { ...selectedDate, id: index + 1, dateString: `${selectedDate.day}.${formattedMonth}.${selectedDate.year}` };
                            })}
                            calendarStatuses={calendarStatuses}
                            selectedCalendarStatus={selectedCalendarStatus}
                            handleCalendarStatusChange={handleCalendarStatusChange}
                            handleChangeModsOnCategory={(item) => { handleChangeModsOnCategory(item) }}
                            productCategories={productCategories}
                            selectedCategories={selectedCategories}
                            addSelectedCategory={addSelectedCategory}
                            deleteSelectedCategory={deleteSelectedCategory}
                            listIsOpen={listIsOpen}
                            handleIsListOpen={() => { setListIsOpen(!listIsOpen) }}
                            closeDayParams={() => { setViewStatusChanger(!viewStatusChanger) }}
                            handleSetDayParams={() => {setIsModalConfirm(true)}}
                            dropCategory={() => { setSelectedCategories([]) }}
                            handleIsListDateOpen={() => {setListDateIsOpen(!listDateIsOpen)}}
                            deleteSelectedDate={(e) => {handleDateSelection(e)}}
                            listDateIsOpen={listDateIsOpen}
                        />
                    </ContentGroupBox>
                </div>

                <button className="calendar__header-button" onClick={handleNextMonth}>&#x25BA;</button>
            </div>
            <table className='calendar-table'>
                <thead>
                    <tr className='calendar-table__days'>
                        <th className='calendar-table__days-item'>Пн</th>
                        <th className='calendar-table__days-item'>Вт</th>
                        <th className='calendar-table__days-item'>Ср</th>
                        <th className='calendar-table__days-item'>Чт</th>
                        <th className='calendar-table__days-item'>Пт</th>
                        <th className='calendar-table__days-item'>Сб</th>
                        <th className='calendar-table__days-item'>Вс</th>
                    </tr>
                </thead>
                <tbody>
                    {monthData.map((week, index) => (
                        <tr key={index} className="calendar-row">
                            {week.map((day, index) => (
                                <td
                                    key={index}
                                    className={`day ${day.disabled ? 'out-of-month' : ''} 
                                    ${day.dayStatus == 'weekend' ? 'isWeekend' : ''}
                                    ${day.dayStatus == 'closed' ? 'isClosed' : ''}
                                    inactive
                                    `}
                                    onClick={() => handleDateSelection(day)}
                                    data-label={` ${day.dayName} `}
                                >
                                    <DayItem
                                        day={day}
                                        openDayParams={() => handleOpenDayModal(day)}
                                        isSelected={selectedDates.some((selectedDate) =>
                                            selectedDate.day === day.day &&
                                            selectedDate.month === day.month &&
                                            selectedDate.year === day.year
                                        )}
                                    >
                                    </DayItem>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal
                title={'День: '.selectedDates > 0 ? selectedDates[0].day + '.' + selectedDates[0].month + '.' + selectedDates[0].year : null}
                isOpenModal={isModalDay}
                closeModal={handleCloseDayModal}
                className="modal__day"
            >
                <div className='modal__day-change'>
                    <DayParams
                        calendarStatuses={calendarStatuses}
                        selectedCalendarStatus={selectedDates[0]?.dayStatus ?? selectedCalendarStatus}
                        handleCalendarStatusChange={handleCalendarStatusChange}
                        handleChangeModsOnCategory={(item) => { handleChangeModsOnCategory(item) }}
                        productCategories={productCategories}
                        selectedCategories={selectedCategories}
                        addSelectedCategory={addSelectedCategory}
                        deleteSelectedCategory={deleteSelectedCategory}
                        listIsOpen={listIsOpen}
                        handleIsListOpen={() => { setListIsOpen(!listIsOpen) }}
                        closeDayParams={handleCloseDayModal}
                        handleSetDayParams={handleSetDayParams}
                        dropCategory={() => { setSelectedCategories([]) }}
                    />
                </div>
            </Modal>
            <ModalYesNo
                isOpenModal={isModalConfirm}
                closeModal={() => setIsModalConfirm(false)}
                actionBtnNo={() => setIsModalConfirm(false)}
                actionBtnYes={() => {
                    handleSetDayParams();
                    setIsModalConfirm(false);
                }}
                title={`Сохранить изменения?`}
            >
                <div className='calendar__save-data_warning'>
                    Ранее имевшиеся данные будут перезаписаны
                </div>
            </ModalYesNo>
        </div>
    );
}