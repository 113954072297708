//Реакт
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
//Api и библиотеки
//Модули и контент
import { ContextApp } from "../../../../modules/app/appContext";
import { useBookingContext } from '../../bookingContext';
//Компоненты
import Calendar from '../../components/calendar';
import DropdownWrapper from '../../../../components/dropdown-wrapper';
import SlotTimes from '../../components/slot-times';
import Contacts from "./components/contacts";
import Header from "./components/tabs/header";
import Info from "./components/info";
//Табы
import Card from "./components/tabs/card";
import Certificate from "./components/tabs/certificate";
import Balance from "./components/tabs/balance";
//Mui icons & components
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//Стили
import './styles.css';

export default function StepBuy({ 
    nextStep, 
    handleBookByBalance, 
    handleBookByCard, 
    handleBookByCertificate,
    hasBookByCard,
    hasBookByBalance,
    hasBookByCertificate,
 }) {
    const { checkPermission } = useContext(ContextApp);
    const [_tab, set_tab] = useState(null);
    const [_isContactsOpen, set_isContactsOpen] = useState(false);
    const [initialData, setInitialData] = useState([null, null]);
    const [showParams, setShowParams] = useState('calendar');
    const [productType, setProductType] = useState('main');
    const {
        isAuth,
        bookingData,
        fetchSlotTime,
        fetchPromocodeCheck,
        fetchCertificateCheck,
        setTimeId,
        setBalanceId,
        setUseCertificate,
        setCountPeople,
        setPromocodeString,
        setCertificateString,
        setProductId,
        setSelectedDate,
        resetPromocodeFields,
        setProductTime,
        setName,
        setEmail,
        setPhone,
    } = useBookingContext();

    const {
        products,
        productTime,
        balanceId,
        balanceProducts,
        balances,
        certificate,
        promocode,
        slotTime,
        isLoadingSlots,
        timeId,
        useCertificate,
        countPeople,
        productId,
        selectedDate,
        user
    } = bookingData;

    const { promocodeString, promocodeLabel, promocodeErrors, promocodeDiscount } = promocode;
    const { certificateString, certificateErrors, certificateMinutes } = certificate;
    const { name, email, phone } = user;

    const mainProducts = products.filter((product) => product.code === 'main');
    const additionalProducts = products.filter((product) => product.code !== 'main');
    const minutesByMainProducts = mainProducts.map((product) => product.minutes);
    const minutesByAdditionalProducts = additionalProducts.map((product) => product.minutes);

    const userCertificates = useSelector((state) => state.accountOption.userCertificates);
    
    useEffect(() => {
        const fetchData = async () => {
          if (!selectedDate) return;
      
          try {
            const showAll = isAuth && _tab !== "certificate";
            const result = await fetchSlotTime(selectedDate, showAll);
            
            if (!result.some(slot => slot.timeId === timeId)) {
              setTimeId(null);
              handleCalendarChange(initialData[0]);
            }
          } catch (error) {}
        };
      
        fetchData();
      }, [selectedDate, _tab]);

    useEffect(() => {
        if (promocodeString) {
            setProductType('main');
            if (productId) fetchPromocodeCheck(promocodeString, productId);
        } else {
            const chosenTimeSlot = Object.values(slotTime).find((slot) => slot.timeId === timeId);
            if (chosenTimeSlot) {
                setProductType(chosenTimeSlot.timeId >= 22 && chosenTimeSlot.timeId <= 25 ? 'additional' : 'main');
            }
            resetPromocodeFields();
        }
    }, [promocodeString, productId]);

    useEffect(() => {
        if (productType === 'additional' && promocodeDiscount == null) {
            setProductType('additional');
        } else {
            setProductType('main');
        }
    }, [promocodeDiscount]);

    useEffect(() => {
        if (productTime) {
            const selectedProduct = productType === 'main' ? mainProducts : additionalProducts;
            const foundProduct = selectedProduct.find((product) => product.minutes === productTime);
            setProductId(foundProduct ? foundProduct.id : null);
        }
    }, [productType]);

    const handleDataChange = (index, newData) => {
        const updatedData = [...initialData];
        updatedData[index] = newData;
        setInitialData(updatedData);
    };

    const handleCalendarChange = (newData) => {
        setSelectedDate(newData);
        handleDeleteItem(1, [newData, null]);
    };

    const handleDeleteItem = (index, newData) => {
        if (index) setTimeId(null);
        else setSelectedDate(null);
        handleChoice(index);
        setInitialData(newData);
    };

    const handleChoice = (index) => {
        if (index === 0) setShowParams('calendar');
        else if (index === 1) setShowParams('slots');
    };

    const handleSlotChange = (newData) => {
        const chosenTimeSlot = slotTime.find((slot) => slot.dateStart === newData);
        if (chosenTimeSlot) {
            setTimeId(chosenTimeSlot.timeId);
            setProductType(chosenTimeSlot.timeId >= 22 && chosenTimeSlot.timeId <= 25 ? 'additional' : 'main');
        }
        handleDataChange(1, newData);
        setShowParams('calendar');
    };

    const handleCountChange = (value) => {
        setCountPeople(value);
    };

    const handleCheckCertificate = () => {
        fetchCertificateCheck(certificateString);
    };

    const handleProductChange = (value) => {
        if (_tab == "balance") {
           setTimeByBalanceId(balanceId);
        }
        if (value && !useCertificate) {
            const selectedProduct = productType === 'main' ? mainProducts : additionalProducts;
            const foundProduct = selectedProduct.find((product) => product.minutes === value);
            setProductId(foundProduct ? foundProduct.id : null);
        } 
        setProductTime(value);
    };

    const handleBalanceChange = (value) => {
        setTimeByBalanceId(value);
        setBalanceId(value);
    };

    const setTimeByBalanceId = (value) => {
        const balanceTypeId = getSelectedBalanceTypeId(value);
        const selectedSlot = getSlotByTimeId(timeId);
        if (balanceTypeId && selectedSlot) {
           if (!selectedSlot.balance_types.includes(balanceTypeId)) {
              setTimeId(null);
              handleCalendarChange(initialData[0]);
           }  
        }
    }

    const handleChangeContactsState = () => {
        set_isContactsOpen(!_isContactsOpen)
    }

    const getSelectedBalanceTypeId = (value) => {
      if (value !== null && value !== undefined) {
        const balance = balances.find(elem => elem.id === value);
        return balance ? balance.type_id : null;
      }

      return null;
    };
    
    const getSlotByTimeId = (value) => {
      if (value !== null && Array.isArray(slotTime)) { // Проверяем, что slotTime — это массив
        return slotTime.find(elem => elem.timeId === value) ?? null;
      }
      return null;
    };

    const tabs = [
      {
        label: "Депозитом",
        sublabel: <div className="tab-sublabel"><div>Спорт </div><EmojiEventsIcon/></div>,
        value: "balance",
        permission: () => (checkPermission("account.booking.booking-by-balance.create") && hasBookByBalance),
      },
      {
        label: "Картой",
        sublabel: null,
        value: "card",
        permission: () => hasBookByCard,
      },
      {
        label: "Сертификатом",
        sublabel: null,
        value: "certificate",
        permission: () => hasBookByCertificate,
      },
    ];

    useEffect(() => {
        const firstAllowedTab = tabs.find(tab => tab.permission());
        set_tab(firstAllowedTab?.value || tabs[0].value);
    }, []); 

    const validateFields = () => {
        let errors = [];
      
        switch(_tab) {
            case "balance":
                if (!timeId) {
                    errors = ["Нужно выбрать время"];
                }
                if (!selectedDate) {
                    errors = ["Нужно выбрать дату"];
                }
                if (!balanceId) {
                    errors = ["Нужно выбрать один из депозитов"]
                }
                if (!productTime) {
                    errors = ["Нужно выбрать один из продуктов"]
                }
                if (!name || !email || !phone) {
                    errors = ["Заполните контактные данные"];
                }
                break;
            case "card":
                if (!timeId) {
                    errors = ["Нужно выбрать время"];
                }
                if (!selectedDate) {
                    errors = ["Нужно выбрать дату"];
                }
                if (!productId) {
                    errors = ["Нужно выбрать один из продуктов"]
                }
                if (!name || !email || !phone) {
                    errors = ["Заполните контактные данные"];
                }
                break;
            case "certificate":
                if (!timeId) {
                    errors = ["Нужно выбрать время"];
                }
                if (!selectedDate) {
                    errors = ["Нужно выбрать дату"];
                }
                if (!certificateString) {
                    errors = ["Нужно ввести номер сертификата"]
                }
                if (!name || !email || !phone) {
                    errors = ["Заполните контактные данные"];
                }
                break;
            default:
                errors = ['Не выбран тип оплаты']
                break;
        }
      
        return errors;
      };

    const errors = validateFields();

    return (
        <div className="step-buy__content">
            <Header
              tabs={tabs}
              currentTab={_tab}
              onChangeTab={(value) => set_tab(value)}
            />
            <Info
                messages={errors}
            />
            <Contacts 
              handleChangeContactsState={handleChangeContactsState}
              isContactsOpen={_isContactsOpen}
              setEmail={(e) => {setEmail(e)}}
              setPhone={(e) => {setPhone(e)}}
              setName={(e) => {setName(e)}}
              name={name}
              email={email}
              phone={phone}
            />
            {_tab === "card" &&
              <Card
                selectedSlot={getSlotByTimeId(timeId)}
                countPeople={countPeople}
                handleCountChange={handleCountChange}
                productTime={productTime}
                productType={productType}
                minutesByMainProducts={minutesByMainProducts}
                minutesByAdditionalProducts={minutesByAdditionalProducts}
                handleProductChange={handleProductChange}
                promocodeErrors={promocodeErrors}
                promocodeString={promocodeString}
                setPromocodeString={setPromocodeString}
                promocodeLabel={promocodeLabel}
                handleBookByCard={handleBookByCard}
              >
                <div className="step-buy__content-row">
                    <DropdownWrapper
                        className="step-buy__content-row_item"
                        placeholder="Выберите дату"
                        IconComponent={CalendarTodayIcon}
                        initialData={initialData}
                        onDelete={handleDeleteItem}
                        onChoice={handleChoice}
                        isLoading={isLoadingSlots}
                    >
                        {showParams === 'calendar' ? (
                            <Calendar onSelect={(value) => handleCalendarChange(value)} />
                        ) : (
                            <SlotTimes
                                type="certificate"
                                promocodeDiscount={promocodeDiscount}
                                productTime={productTime}
                                products={products}
                                isLoading={isLoadingSlots}
                                slotTime={slotTime}
                                onSelect={(value) => handleSlotChange(value)}
                                balanceType={
                                _tab == "balance"
                                ? getSelectedBalanceTypeId(balanceId)
                                : null}
                            />
                        )}
                    </DropdownWrapper>
                </div>
              </Card>
            }
            {_tab === "certificate" &&
              <Certificate
                certificateMinutes={certificateMinutes}
                countPeople={countPeople}
                handleProductChange={handleProductChange}
                userCertificates={userCertificates}
                certificateErrors={certificateErrors}
                certificateString={certificateString}
                setCertificateString={setCertificateString}
                handleCheckCertificate={handleCheckCertificate}
                handleBookByCertificate={handleBookByCertificate}
              >
                <div className="step-buy__content-row">
                    <DropdownWrapper
                        className="step-buy__content-row_item"
                        placeholder="Выберите дату"
                        IconComponent={CalendarTodayIcon}
                        initialData={initialData}
                        onDelete={handleDeleteItem}
                        onChoice={handleChoice}
                        isLoading={isLoadingSlots}
                    >
                        {showParams === 'calendar' ? (
                            <Calendar onSelect={(value) => handleCalendarChange(value)} />
                        ) : (
                            <SlotTimes
                                type="certificate"
                                promocodeDiscount={promocodeDiscount}
                                productTime={productTime}
                                products={products}
                                isLoading={isLoadingSlots}
                                slotTime={slotTime}
                                onSelect={(value) => handleSlotChange(value)}
                                balanceType={
                                _tab == "balance"
                                ? getSelectedBalanceTypeId(balanceId)
                                : null}
                            />
                        )}
                    </DropdownWrapper>
                </div>
              </Certificate>
            }
            {_tab === "balance" &&
              <Balance
                selectedSlot={getSlotByTimeId(timeId)}
                productTime={productTime}
                balanceProducts={balanceProducts}
                countPeople={countPeople}
                handleProductChange={handleProductChange}
                balanceId={balanceId}
                balances={balances}
                handleBalanceChange={handleBalanceChange}
                handleBookByBalance={handleBookByBalance}
               >
                    <div className="step-buy__content-row">
                        <DropdownWrapper
                            className="step-buy__content-row_item"
                            placeholder="Выберите дату"
                            IconComponent={CalendarTodayIcon}
                            initialData={initialData}
                            onDelete={handleDeleteItem}
                            onChoice={handleChoice}
                            isLoading={isLoadingSlots}
                        >
                            {showParams === 'calendar' ? (
                                <Calendar onSelect={(value) => handleCalendarChange(value)} />
                            ) : (
                                <SlotTimes
                                    type="certificate"
                                    promocodeDiscount={promocodeDiscount}
                                    productTime={productTime}
                                    products={products}
                                    isLoading={isLoadingSlots}
                                    slotTime={slotTime}
                                    onSelect={(value) => handleSlotChange(value)}
                                    balanceType={
                                    _tab == "balance"
                                    ? getSelectedBalanceTypeId(balanceId)
                                    : null}
                                />
                            )}
                        </DropdownWrapper>
                    </div>
               </Balance>
            }
        </div>
    );
}
