const consts = {
    SET_LANGS:                          "OPTION-SET_LANGS",
    SET_CLIENT_ROLES:                   "OPTION-SET_CLIENT_ROLES",
    SET_PERMISSIONS:                    "OPTION-SET_PERMISSIONS",
    SET_BOOKING_BALANCE_TYPES:          "OPTION-SET_BOOKING_BALANCE_TYPES",
    SET_ROLES:                          "OPTION-SET_ROLES",
    SET_PRODUCTS:                       "OPTION-SET_PRODUCTS",
    SET_CATEGORY_PRODUCTS:              "OPTION-SET_CATEGORY_PRODUCTS",
    SET_COMPETITION_PRODUCTS:           "OPTION-SET_COMPETITION_PRODUCTS",
    SET_PRODUCT_CATEGORIES:             "OPTION-SET_PRODUCT_CATEGORIES",
    SET_PLATFORMS:                      "OPTION-SET_PLATFORMS",
    SET_ORDER_TYPES:                    "OPTION-SET_ORDER_TYPES",
    SET_BANNER_TYPES:                   "OPTION-SET_BANNER_TYPES",
    SET_BANNER_STATUSES:                "OPTION-SET_BANNER_STATUSES",
    SET_PAYMENT_STATUSES:               "OPTION-SET_PAYMENT_STATUSES",
    SET_CALENDAR_STATUSES:              "OPTION-SET_CALENDAR_STATUSES",
    SET_PROMOCODES:                     "OPTION-SET_PROMOCODES",
    SET_COMPETITIONS:                   "OPTION-SET_COMPETITIONS",
    SET_COMPETITION_TEAM_DISCIPLINES:   "OPTION-SET_COMPETITION_TEAM_DISCIPLINES",
    SET_PAYMENT_STATUSES_CERTIFICATE:   "OPTION_SET_PAYMENT_STATUSES_CERTIFICATE",
    SET_SURVEYS:                        "OPTION-SET_SURVEYS"
};
  
export default consts;
