// React
import React, { useEffect, useState } from 'react';
//Mui Icons
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// Стили
import './styles.css';

export default function DayItem({ day = null, openDayParams = () => {}, isSelected = false }) {
    const handleOpenDayParams = () => {
        openDayParams()
    }
    return (
        <div className="day-item">
            <div className='day-item__header'>
                <div className='day-item__header-date'> 
                    <div className='day-item__header-add'>
                        {isSelected
                            ? <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                            : <CheckBoxOutlineBlankOutlinedIcon></CheckBoxOutlineBlankOutlinedIcon>
                        }
                    </div>         
                    <div className='day-item__header-date_title'>
                        {day ? day.day + '.' + day.month + '.' + day.year : null}
                    </div>
                </div>
  
                <CalendarTodayIcon onClick={handleOpenDayParams}  className='day-item__header-date_icon'/>
            </div>
            <div className='day-item__body'>
                {day && day.events.map((event, index) => (
                    <div
                        key={index}
                        className='day-item__body-row'
                    >
                        <div className='day-item__body-row-label'>
                            {event.name}
                        </div>
                        <div className={` ${event.is_active ? 'day-item__body-row-icon--success' : 'day-item__body-row-icon--danger'}`}>
                            {event.is_active ?
                                <VerifiedOutlinedIcon></VerifiedOutlinedIcon>
                                :
                                <ErrorOutlineOutlinedIcon></ErrorOutlineOutlinedIcon>
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}