//Реакт
import React from 'react';
//Компоненты
import Input from "../../../../components/input";
//Mui Icons
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
//Стили
import "./index.js";
import "../../styles.css";

export default function Contacts({
  children,
  className='',
  handleChangeContactsState,
  isContactsOpen,
  setName,
  setEmail,
  setPhone,
  name,
  email,
  phone,
  ...other}) {
 
  const handlePhoneChange = (event) => {
    let str = event.target.value.replace(/[^0-9]/g, '');
    if (str.length && str[0] !== '7') str = `7${str}`;
    if (str.length >= 11) str = str.substr(0, 11);
    setPhone(str);
  }

  return <>
    <div className='step-buy__content-row-contacts'>
      <div onClick={handleChangeContactsState} className='step-buy__content-row-contacts-list'>
        <label className="step-buy__content-row-contacts-list_label">Контакты</label>
        <div className={`${isContactsOpen ? 'step-buy__content-row-contacts-list-indicator--rotated' : 'step-buy__content-row-contacts-list-indicator'}`}>
          <KeyboardArrowUpOutlinedIcon />
        </div>
      </div>
      <div className={`${isContactsOpen ? 'step-buy__content-row-contacts-content--open' : 'step-buy__content-row-contacts-content--close'}`}>
        <Input onChange={(e) => setName(e.target.value)} value={name} className='step-buy__content-row-contacts-content_item'>Имя</Input>
        <Input onChange={(e) => setEmail(e.target.value)} value={email} className='step-buy__content-row-contacts-content_item'>E-mail</Input>
        <Input onChange={handlePhoneChange} value={(typeof phone === 'string' && phone.length > 0) ? `+${phone}` : ''} placeholder="+7 (___) ___-__-__" className='step-buy__content-row-contacts-content_item'>Телефон</Input>
      </div>
    </div>
  </>
}
