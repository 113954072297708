//Реакт
import React from "react";
//Компоненты
import Label from "../label";
import MessageError from "../../message/__errors";
//Стили
import '../styles.css';
import './styles.css';

export default function InputNumber({value='', children, placeholder, errors, hideArrows = false ,onChange, className = '', ...other}){
    if(placeholder === undefined) placeholder = children;
    const _onChange = (e) => {
        let str = e.target.value.replace(/[^0-9]/g, '');
        e.target.value = str;
        onChange(e);
    }
    return <>
        <Label>{children}</Label>
        <input
            {...other}
            type="number"
            className={`input ${className} ${hideArrows ? 'input-number--hide-arrows' : ''}`}
            placeholder={placeholder}
            onChange={_onChange}
            value={value != null ? value : ''}
        />
        {errors ? <MessageError errors={errors} sx={{width: "100%"}}/> : null}
    </>
}