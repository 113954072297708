//React
import React from 'react';
//Компоненты
import Products from "../../../../../components/products";
import {default as Cert} from "../../../../../components/certificate";
import Button from "../../../../../../../components/button";
//Стили
import "./styles.css";
import "../../../styles.css";

export default function Certificatete({
  certificateMinutes,
  countPeople,
  handleProductChange,
  userCertificates,
  certificateErrors,
  certificateString,
  setCertificateString,
  handleCheckCertificate,
  handleBookByCertificate,
  children
}){
  return (
    <div className="step-buy__content-body">
      <div className="step-buy__content-row">
        <label className="step-buy__content-row_label">Время (мин)</label>
        <Products
          className="step-buy__content-row_item"
          selectedProduct={certificateMinutes}
          products={certificateMinutes ? [certificateMinutes] : []}
          countPeople={countPeople}
          onChange={handleProductChange}
        />
      </div>
      <div className="step-buy__content-row">
        <Cert
          options={userCertificates}
          errors={certificateErrors}
          value={certificateString}
          onChange={(e) => setCertificateString(e.target.value)}
          onCheck={handleCheckCertificate}
          className={`step-buy__content-row_item`}
        >
          Номер сертификата
        </Cert>
        {certificateString && certificateMinutes && (
          <div className="step-buy__content-row_success-label">
            Сертификат {certificateString} действителен!
          </div>
        )}
      </div>
      {children}
      <Button className="step-buy__content-button" onClick={handleBookByCertificate}>
        Оформить
      </Button>
    </div>
  );
};
