//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import ViewHandler from "../../../../../inc/ViewHandler";
import ApiBalance from "../../../../../app/api/authorized/admin/booking/ApiBalance";
import { route } from "../../../../../inc/Routes";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
//Mui icons
import BrowseGalleryOutlinedIcon from '@mui/icons-material/BrowseGalleryOutlined';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonIcon from '@mui/icons-material/Person';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import SegmentIcon from '@mui/icons-material/Segment';
//Стили
import './styles.css';

export default function BalanceIndex({ ...other }) {
    return <Page {...other}>
        <BalanceIndexContent {...other} />
    </Page>
}

function BalanceIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_balance, set_balance] = useState('');
    const [_type, set_type] = useState('');
    const [_fio, set_fio] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle(`Депозиты`);
        contextContent.setTitle(`Депозиты`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="user"
            name="user"
        >
            <div className="header-row__center">
                <PersonIcon/>
                <div className="header-row__center_text">Пользователь</div> 
            </div> 
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance"
            name="balance"
        >
            <div className="header-row__center">
                <MoreTimeIcon/>
                <div className="header-row__center_text">Баланс</div> 
            </div> 
        </ListHeaderItem>,
        <ListHeaderItem
            key="reserved"
            name="reserved"
        >
            <div className="header-row__center">
                <AcUnitIcon/>
                <div className="header-row__center_text">Резерв</div> 
            </div> 
        </ListHeaderItem>,
        <ListHeaderItem
            key="type"
            name="type"
        >
            <div className="header-row__center">
                <WebStoriesIcon/>
                <div className="header-row__center_text">Тип</div> 
            </div> 
        </ListHeaderItem>,
        <ListHeaderItem
            key="times"
            name="times"
            isSort={false}
        >
            <div className="header-row__center">
                <SegmentIcon/>
                <div className="header-row__center_text">Транзакции</div> 
            </div>
        </ListHeaderItem>,
         <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div> 
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_fio} onChange={(e) => { set_fio(e.target.value) }}>Пользователь</InputText>,
        <InputNumber className="input_wf" value={_balance} onChange={(e) => { set_balance(e.target.value) }}>Баланс</InputNumber>,
        <InputText className="input_wf" value={_type} onChange={(e) => { set_type(e.target.value) }}>Тип</InputText>,
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-balances_column-width" key="user" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}><a href={route(`authorized.admin.user.users.show`,{id: row.user_id})}>{row.user_fio}</a></ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="balance" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.balance}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="reserved" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.reserved}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="type" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="times" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>
                <a target="_blank" href={route("authorized.admin.booking.times.index", {balanceId: row.id})}>
                    <BrowseGalleryOutlinedIcon/>
                </a>
            </ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBalance}
            renderRow={renderRow}
            searchParams={{ id: _id, userFio: _fio, balance: _balance, type: _type }}
        />
    </>
}
