//Реакт
import React from "react";
//Компоненты
import Modal from "../modal";
import Button from "../../button";
//Стили
import "./styles.css";

export default function ModalYesNo({
    children,
    actionBtnYes,
    actionBtnNo,
    actionBtnCancel,
    closeModal,
    ...other
}){
    return (
        <Modal
            closeModal={closeModal}
            className="yes-no-modal"
            {...other}
        >
            <div className="modal-yes-no">
                <div className="modal-yes-no__body">
                    {children}
                </div>
                <div className="modal-yes-no__footer">
                    <div className="modal-yes-no__footer-left">
                        <Button 
                            className="btn-success"
                            onClick={actionBtnYes}
                        >Да</Button>
                    </div>
                    <div className="modal-yes-no__footer-right">
                        <Button 
                            className="btn-danger"
                            onClick={actionBtnNo}
                        >Нет</Button>
                        <Button 
                            onClick={actionBtnCancel ? actionBtnCancel : closeModal}
                        >Отмена</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}