//React
import React from 'react';
//Компоненты
import Products from "../../../../../components/products";
import Balances from "../../../../../components/balances";
import Button from "../../../../../../../components/button";
//Стили
import "./styles.css";
import "../../../styles.css";

export default function Balance({
  selectedSlot,
  productTime,
  balanceProducts,
  countPeople,
  handleProductChange,
  balanceId,
  balances,
  handleBalanceChange,
  handleBookByBalance,
  children
}) {
  return (
    <div className="step-buy__content-body">
      <div className="step-buy__content-row">
        <label className="step-buy__content-row_label">Время (мин)</label>
        <Products
          className="step-buy__content-row_item"
          selectedSlot={selectedSlot}
          selectedProduct={productTime}
          products={balanceProducts}
          countPeople={countPeople}
          onChange={handleProductChange}
          checkMinutes={false}
        />
      </div>
      <div className="step-buy__content-row">
        <label className="step-buy__content-row_label">Депозит</label>
        <Balances
          className="step-buy__content-row_item--last"
          selectedBalanceId={balanceId}
          balances={balances}
          onChange={handleBalanceChange}
        />
      </div>
      {children}
      <Button className="step-buy__content-button" onClick={handleBookByBalance}>
        Оформить
      </Button>
    </div>
  );
};
