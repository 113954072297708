//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ViewHandler from "../../../../../inc/ViewHandler";
import DateLib from "../../../../../inc/DateLib";
import ApiNews from "../../../../../app/api/authorized/admin/content/ApiNews";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputDate from "../../../../components/inputs/date"
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import Select from "../../../../components/inputs/select";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NumbersIcon from '@mui/icons-material/Numbers';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
//Стили
import './styles.css';

export default function NewsIndex({ ...other }) {
    return <Page {...other}>
        <NewsIndexContent {...other} />
    </Page>
}

function NewsIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_annotation, set_annotation] = useState('');
    const [_priority, set_priority] = useState('');
    const [_date_start, set_date_start] = useState('');
    const [_date_end, set_date_end] = useState('');
    
    useLayoutEffect(() => {
        contextPage.setTitle(`Новости`);
        contextContent.setTitle(`Новости`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            <div className="header-row__center">
                <NewspaperIcon/>
                <div className="header-row__center_text">Новость</div> 
            </div>  
        </ListHeaderItem>,
         <ListHeaderItem
            key="file_name"
            name="file_name"
            isSort={false}
        >
            <div className="header-row__center">
                <InsertDriveFileIcon/>
                <div className="header-row__center_text">Файл</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="file_url"
            name="file_url"
            isSort={false}
        >
            <div className="header-row__center">
                <AspectRatioIcon/>
                <div className="header-row__center_text">Превью</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="priority"
            name="priority"
        >
            <div className="header-row__center">
                <StackedBarChartIcon/>
                <div className="header-row__center_text">Приоритет</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            <div className="header-row__center">
                <EventAvailableIcon/>
                <div className="header-row__center_text">Дата активации</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            <div className="header-row__center">
                <EventBusyIcon/>
                <div className="header-row__center_text">Дата окончания</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
        >
            <div className="header-row__center">
                <FontDownloadIcon/>
                <div className="header-row__center_text">Активация</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>  
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Акция</InputText>,
        <InputText className="input_wf" value={_priority} onChange={(e) => { set_priority(e.target.value) }}>Приоритет</InputText>,
        <InputDate className="input_wf" value={_date_start} onChange={(e) => { set_date_start(e.target.value) }}>Дата активации</InputDate>,
        <InputDate className="input_wf" value={_date_end} onChange={(e) => { set_date_end(e.target.value) }}>Дата окончания</InputDate>,
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
    ];
    
    const renderRow = (row, i) => {
        return [
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
            <ListBodyItem key="name" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.name}</ListBodyItem>,
            <ListBodyItem key="file_name" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{ row.file_name ? row.file_name.substr(row.file_name.length - 10, 10) : null}</ListBodyItem>,
            <ListBodyItem key="file_url" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>
                {
                    row.file_url ? 
                        <a target="_blank" href={row.file_url}>
                            <img src={`${row.file_url}?t=${new Date().getTime()}`} width="100%" alt={row.file_url}/>
                        </a>
                    : null
                }
            </ListBodyItem>,
            <ListBodyItem key="priority" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.priority}</ListBodyItem>,
            <ListBodyItem key="date_start" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem key="date_end" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem key="is_active" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>
                {
                    row.is_active ? <div className="is-active_icon--success"><VerifiedIcon style={{fill: 'rgb(80 157 207)' }}/></div>
                    : <div className="is-active_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>,
            <ListBodyItem key="id" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiNews}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, priority: _priority,  dateStart: _date_start,  dateEnd: _date_end}}
        />
    </>
}