import React, { useEffect, useState } from 'react';
//Mui Icons
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
// Компоненты
import SelectItem from './components/select-item/index';
// Стили
import './styles.css';

export default function SlotTimes({ promocodeDiscount, slotTime, onSelect, productTime = null, products = [], type = 'card', balanceType = null }) {
    const [_slots, set_slots] = useState([]);
    const columns = 6;
    //Выбор времени выбранной даты
    const handleSlotClick = (value) => {
        if((productTime || type === 'certificate') && 
            !isDisableByDateStart(value) && 
            !isDisableByBalanceType(value) &&
            !isDisableByTimeCapability(value)){
            onSelect(value);
        }
    }

    //Установкука значений слотов при переключяении даты (и соответственно смены таймслотов)
    useEffect(() => {
        let times = [];
        if (Object.keys(slotTime).length > 0) {
            Object.values(slotTime).forEach((time) => times.push(time.dateStart));
        }
        set_slots(times);
    }, [slotTime]);

    //Выстраивание данных по рядам для таблицы dropdown
    const createRows = (slots, columns) => {
        const rows = [];
        for (let i = 0; i < slots.length; i += columns) {
            const row = slots.slice(i, i + columns);
            while (row.length < columns) {
                row.push(null);
            }
            rows.push(row);
        }
        return rows;
    };

    //Получение продуктов по типу
    const getProductsByType = (type) => {
        return products.find(product => product.minutes === productTime && product.code === type)
    }

    const getBusyTimeByStart = (slot) => {
        const curSlot = slotTime.find(elem => elem.dateStart == slot);
        return curSlot.timeFree/30 * 100;
    };

    const isDisableByDateStart = (slot) => {
      const currDate = new Date(); // Текущая дата и время
      const hours = currDate.getHours(); // Текущий час
      const minutes = currDate.getMinutes(); // Текущие минуты

      const curSlot = slotTime.find(elem => elem.dateStart == slot); // Находим слот
      const slotDate = new Date(curSlot.date); // Дата слота

      // Преобразуем время окончания слота в часы и минуты
      const slotHours = Number(curSlot.dateEnd.split(':')[0]);
      const slotMinutes = Number(curSlot.dateEnd.split(':')[1]);


      // Если дата слота равна текущей дате, проверяем время
      if (slotDate.getDate() === currDate.getDate() &&
          slotDate.getMonth() === currDate.getMonth() &&
          slotDate.getFullYear() === currDate.getFullYear()) {
        return (
          slotHours < hours || (slotHours === hours && slotMinutes <= minutes)
        );
      }

      // Во всех остальных случаях слот доступен
      return false;
    }
      
    const isDisableByBalanceType = (time) => {
      const chosenTimeSlot = slotTime.find(slot => slot.dateStart === time);

      if (balanceType && chosenTimeSlot) {
        return !chosenTimeSlot.balance_types.includes(balanceType);
      }
      
      return false;
    };

    const isDisableByTimeCapability = (time) => {
      const chosenTimeSlot = slotTime.find(slot => slot.dateStart === time);
      return chosenTimeSlot.timeFree <  productTime;
    }
      

    //Получение данных для подсказки при наведении
    const getTooltipData = (time) => {
        const chosenTimeSlot = slotTime.find(slot => slot.dateStart === time);
        if (chosenTimeSlot) {
            let product = {};
            if ((chosenTimeSlot.timeId >= 22 && chosenTimeSlot.timeId <= 25) && !promocodeDiscount && !chosenTimeSlot.isDayOff) {
                product = getProductsByType('additional') ?? getProductsByType('main');
            } else {
                product = getProductsByType('main');
            }
    
            return (
                <div className="time-slots__slot--tooltip-content">
                    {product && Object.keys(product).length > 0 ? (
                        <>
                            <div className='time-slots__slot--tooltip-content-row'>
                                <div className="time-slots__slot--tooltip-content-row-price">{product.price} ₽</div>
                                <div className='time-slots__slot--tooltip-content-row-info'>
                                    {(product.old_price || promocodeDiscount > 0) && (
                                        <div className="time-slots__slot--tooltip-content-row-info-benefit">
                                            Экономия {promocodeDiscount > 0 ? (promocodeDiscount * product.price) : (product.old_price - product.price)} ₽
                                        </div>
                                    )}
                                    <div className='time-slots__slot--tooltip-content-row-info-icon'>
                                        {product.old_price || promocodeDiscount ? <CardGiftcardIcon /> : <CreditCardIcon />}
                                    </div>
                                </div>
                            </div>
                            <div className='time-slots__slot--tooltip-content-time-free'>Свободно {chosenTimeSlot.timeFree} мин.</div>
                        </>
                    ) : (
                        type === 'certificate' ? (
                            <div className='time-slots__slot--tooltip-content-time-free-certificate'>Свободно {chosenTimeSlot.timeFree} мин.</div>
                        ) : (
                            'Выберите продукт'
                        )
                    )}
                </div>
            );
        } else {
            return 'Выберите продукт';
        }
    };

    const rows = createRows(_slots, columns);

    return (
        <div className="time-slots">
            <div className="time-slots__header"></div>
            <table>
                <thead>
                    <tr className="time-slots__days">
                        <th className="time-slots__days-item"></th>
                        <th className="time-slots__days-item"></th>
                        <th className="time-slots__days-item"></th>
                        <th className="time-slots__days-item"></th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((slot, colIndex) => (
                                <td
                                    key={colIndex}
                                    className={`time-slots__slot ${slot !== null ? 'inactive' : 'out-of-date'}`}
                                    onClick={ () => handleSlotClick(slot)}
                                >
                                    {slot !== null ? 
                                        <SelectItem
                                            key={slot}
                                            busyTime={getBusyTimeByStart(slot)}
                                            isDisable={
                                              isDisableByDateStart(slot) || 
                                              isDisableByBalanceType(slot) ||
                                              isDisableByTimeCapability(slot)}
                                        >
                                            {slot}
                                        </SelectItem>
                                    : ''}
                                    {slot !== null && (
                                        <div className="time-slots__slot--tooltip">{getTooltipData(slot)}</div>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
