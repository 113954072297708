//React
import React, { useState, useEffect, useRef } from 'react';
//Компоненты
import Label from '../label';
import InputCheckbox from '../checkbox';
import MessageError from '../../message/__errors/message__errors'
//MUI Icons
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './styles.css';

export default function MultiSelect({
    options = {}, value = [], labelNull = null,
    children, onChange, placeholder, errors, className = '',
    readOnly, isSpreaded, ...other
}) {
    const [searchText, setSearchText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [isListOpen, setIsListOpen] = useState(false);
    const [showAllOptions, setShowAllOptions] = useState(false);
    const selectWrapperRef = useRef(null);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (!isListOpen) {
            setIsFocused(false);
            if (value.length === 0) {
                setSearchText("");
            }
        }
    };

    const handleToggle = () => {
        setIsListOpen(!isListOpen);
    };

    const handleSelectOption = (selectedValue) => {
        let newValue;
        if (value.includes(selectedValue)) {
            newValue = value.filter(v => v !== selectedValue);
        } else {
            newValue = [...value, selectedValue];
        }
        
        onChange({ target: { value: newValue } });
        const inputElement = selectWrapperRef.current.querySelector('input');
        if (inputElement) {
            inputElement.focus();
        }
    };

    const handleInputChange = (value) => {
        setShowAllOptions(false);
        setSearchText(value);
    };

    const handleReset = () => {
        handleInputChange('');
        onChange({ target: { value: [] } });
        setIsFocused(false);
        setIsListOpen(false);
        setShowAllOptions(true);
    };

    const filteredOptions = showAllOptions ? Object.keys(options) : Object.keys(options).filter(key => {
        return options[key].toLowerCase().includes(searchText.toLowerCase());
    });

    if (placeholder === undefined) placeholder = children;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectWrapperRef.current && !selectWrapperRef.current.contains(event.target)) {
                setIsListOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectWrapperRef]);

    return (
        <>
            <Label>{children}</Label>
            <div ref={selectWrapperRef} className={`select-wrapper ${className}`}>
                <input
                    type="text"
                    value={searchText}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onClick={handleToggle}
                    placeholder="Поиск..."
                    className='input select-input'
                    readOnly={readOnly}
                    {...other}
                />
                <button className={`select__reset-button ${readOnly ? 'select__reset-button--disabled' : ''}`} onClick={handleReset}>
                    <DeleteOutlineOutlinedIcon />
                </button>
                {isFocused && (
                    <div className={`${!isSpreaded ? 'select__options-dropdown_is-open' : 'select__options-dropdown_is-open--spreaded'}`}>
                        {filteredOptions.map((key) => (
                            <div   onClick={() => handleSelectOption(key)} className='option-item' key={key}>
                                <InputCheckbox
                                    value={value.includes(key)}
                                />
                                <div>{options[key]}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {errors ? <MessageError errors={errors} sx={{ width: "100%" }} /> : null}
        </>
    );
}