import TypeConst from "./consts";

export default function todosReducer(state = {
    paymentStatuses: {},
    paymentTypes: {},
    bookingTypes: {},
    bookingStatuses: {},
    bookingTimeStatuses: {},
    bookingTimeTypes: {},
}, action) {
    switch (action.type) {
        case TypeConst.SET_PAYMENT_STATUSES:
            return {
                ...state,
                paymentStatuses: action.paymentStatuses
            };
        case TypeConst.SET_PAYMENT_TYPES:
            return {
                ...state,
                paymentTypes: action.paymentTypes
            };
        case TypeConst.SET_BOOKING_TYPES:
            return {
                ...state,
                bookingTypes: action.bookingTypes
            };
        case TypeConst.SET_BOOKING_STATUSES:
            return {
                ...state,
                bookingStatuses: action.bookingStatuses
            };
        case TypeConst.SET_BOOKING_TIME_STATUSES:
            return {
                ...state,
                bookingTimeStatuses: action.bookingTimeStatuses
            };
        case TypeConst.SET_BOOKING_TIME_TYPES:
            return {
                ...state,
                bookingTimeTypes: action.bookingTimeTypes
            }
        default:
            return state;
    }
}
