import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ContextBalance } from './balanceContext';
import ApiPromocodes from '../../../app/api/public/booking/promocode/ApiPromocodes';
import ApiBalances from '../../../app/api/authorized/account/booking/ApiBalances';
import ApiBalanceProducts from '../../../app/api/authorized/account/booking/ApiBalanceProducts';
import ApiProfile from '../../../app/api/authorized/common/ApiProfile';

export function ProviderBalance({ children }) {
    const isAuth = useSelector((state) => state.app.isAuth);
    const [buyBalanceData, setBuyBalanceData] = useState({
        balanceProducts: [],
        balances: [],
        promocode: {
            promocodeString: null,
            promocodeErrors: {},
            promocodeLabel: '',
            promocodeDiscount: null,
        },
        user: {
            name: null,
            email: null,
            phone: null,
        },
        balanceId: null,
        balanceOptionId: null,
    });

    useEffect(() => { 

        const fetchBalanceProducts= async () => {
            const apiBalanceProducts= ApiBalanceProducts.Index();
            const responseData = await apiBalanceProducts.getResponse();

            if(responseData) {
                const balanceProducts = responseData.products ?? [];
                setBuyBalanceData(prevData => ({
                    ...prevData,
                    balanceProducts
                }));
            }  else {
                throw new Error('Ошибка при получении продуктов');
            }
        };

        const fetchUserData= async () => {
            const apiUserData= ApiProfile.Show();
            const responseData = await apiUserData.getResponse();

            if(responseData) {
                const email = responseData.email ?? '';
                const name = responseData.name ?? '';
                const phone = responseData.phone ?? '';
                
                setBuyBalanceData(prevData => ({
                    ...prevData,
                    user: {
                        name: name,
                        email: email,
                        phone: phone
                    }
                }));
            }  
        };

        const fetchData = async () => {
            try {      
              if (isAuth) {
                if (buyBalanceData.balances.length === 0) {
                  try {
                    await fetchBalanceProducts();
                  } catch (error) { return; }
                  try {
                    await fetchBalances(); 
                  } catch (error) { return; }
                }
                try {
                  await fetchUserData();
                } catch (error) { return }
              }
            } catch (error) {}
          };
        
        fetchData();
    }, []);

    const fetchBalances= async () => {
        const apiBalances= ApiBalances.Index();
        const responseData = await apiBalances.getResponse();
     
        if(responseData) {
            const balances = responseData.filter(balance => balance.slug !== 'first-time').map(balance => ({
                id: balance.id,
                balance: balance.balance,
                reserved: balance.reserved,
                type_name: balance.type_name,
                slug: balance.slug,
                type_id: balance.type_id
            }));

            setBuyBalanceData(prevData => ({
                ...prevData,
                balances
            }));
        } else {
            throw new Error('Ошибка при получении балансов');
        }
    };

    const fetchPromocodeCheck = async (promocode, productId) => {
        if (promocode && promocode.length > 2 && productId) {
            try {
                const apiPromocodes = ApiPromocodes.Store({ promocode, productId });
                const res = await apiPromocodes.getResponse();
                setBuyBalanceData(prevData => ({
                    ...prevData,
                    promocode: {
                        ...prevData.promocode,
                        promocodeErrors: res ? {} : { promocode: apiPromocodes.errors },
                        promocodeLabel: res ? res.label : '',
                        promocodeDiscount: res ? res.discount : null,
                    }
                }));
            } catch (error) {
                setBuyBalanceData(prevData => ({
                    ...prevData,
                    promocode: {
                        ...prevData.promocode,
                        promocodeErrors: {},
                        promocodeLabel: '',
                        promocodeDiscount: null,
                    }
                }));
            }
        } else {
            setBuyBalanceData(prevData => ({
                ...prevData,
                promocode: {
                    ...prevData.promocode,
                    promocodeErrors: {},
                    promocodeLabel: '',
                    promocodeDiscount: null,
                }
            }));
        }
    };

    const resetPromocodeFields = () => {
        setBuyBalanceData(prevData => ({
            ...prevData,
            promocode: {
                promocodeErrors: {},
                promocodeLabel: '',
                promocodeDiscount: null,
            }
        }));
    };

    const setPromocodeString = (promocodeString) => {
        setBuyBalanceData(prevData => ({ ...prevData, promocode: {promocodeString: promocodeString} }));
    };


    const setBalanceId = (balanceId) => {
        setBuyBalanceData(prevData => ({ ...prevData, balanceId }));
    };

    const setBalanceOptionId = (balanceOptionId) => {
        setBuyBalanceData(prevData => ({ ...prevData, balanceOptionId }));
    };

    const setUser = (updatedUser) => {
        setBuyBalanceData((prevState) => ({
          ...prevState,
          user: {
            ...prevState.user,
            ...updatedUser
          }
        }));
      };
      
      const setName = (name) => {
        setUser({ name });
      };
      
      const setEmail = (email) => {
        setUser({ email });
      };
      
      const setPhone = (phone) => {
        setUser({ phone });
      };

    return (
        <ContextBalance.Provider value={{ 
                buyBalanceData, 
                setBuyBalanceData,
                resetPromocodeFields,
                fetchPromocodeCheck,
                fetchBalances,
                setPromocodeString,
                setBalanceId,
                setBalanceOptionId,
                setName,
                setEmail,
                setPhone
            }}>
            {children}
        </ContextBalance.Provider>
    );
}
