//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ViewHandler from "../../../../../inc/ViewHandler";
import ApiCategory from "../../../../../app/api/authorized/admin/content/ApiCategory";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import MultiSelect from "../../../../components/inputs/milti-select";
import Select from "../../../../components/inputs/select";
//Mui icons
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import NumbersIcon from '@mui/icons-material/Numbers';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
//Стили
import './styles.css';

export default function CategoryIndex({ ...other }) {
    return <Page {...other}>
        <CategoryIndexContent {...other} />
    </Page>
}

function CategoryIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_slug, set_slug] = useState('');
    const [_balanceTypeIds, set_balanceTypeIds] = useState([]);
    const [_isPromotion, set_isPromotion] = useState('')

    const bookingBalanceTypes = useSelector(state => state.adminOption.bookingBalanceTypes);

    const handleBalanceTypesChange = (event) => {
        set_balanceTypeIds(event.target.value);
    }
    
    useLayoutEffect(() => {
        contextPage.setTitle(`Категории`);
        contextContent.setTitle(`Категории`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            <div className="header-row__center">
                <CategoryOutlinedIcon/>
                <div className="header-row__center_text">Категория</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance_type_id"
            name="balance_type_id"
        >
            <div className="header-row__center">
                <WebStoriesIcon/>
                <div className="header-row__center_text">Тип депозита для возврата</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>  
        </ListHeaderItem>,
         <ListHeaderItem
            key="can_be_bought"
            name="can_be_bought"
        >
            <div className="header-row__center">
                <LocalMallOutlinedIcon/>
                <div className="header-row__center_text">Можно купить</div> 
            </div>  
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Категория</InputText>,
        <MultiSelect className="input_wf option-border-none" options={bookingBalanceTypes} value={_balanceTypeIds} onChange={handleBalanceTypesChange}>Тип депозита</MultiSelect>,
        <Select options={['Без акции', 'Акция']} value={_isPromotion } onChange={(e) => {set_isPromotion(e.target.value)}}>Акция</Select>,
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
    ];
    
    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-categories_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-categories_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>
                <div className="admin-categories_name">
                    {row.is_promotion ? <span className="is-active_icon--success"><DiscountOutlinedIcon/></span> : null}
                    {row.name}
                </div>
            </ListBodyItem>,
            // <ListBodyItem className="admin-categories_column-width" key="slug" data-label={header[2].props.children}>{row.slug}</ListBodyItem>,
            <ListBodyItem className="admin-categories_column-width" key="slug" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row?.balance_type?.balance_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-categories_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-categories_column-width" key="can_be_bought" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>
                {
                    row.can_be_bought ? <div className="is-active_icon--success"><VerifiedIcon style={{fill: 'rgb(80 157 207)' }}/></div>
                    : <div className="is-active_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>
        ];
    }
    return <>
    
        <List
            header={header}
            filters={filters}
            ApiModel={ApiCategory}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, slug: _slug, balanceTypes: _balanceTypeIds, isPromotion: _isPromotion }}
        />
    </>
}
