//Реакт
import React from 'react';
//Стили
import "./index.js";
import "../../../styles.css";

export default function Header({
  className = '',
  tabs = [], // Массив вкладок
  currentTab, // Текущая активная вкладка (значение, например "card")
  onChangeTab, // Обработчик смены вкладки (принимает значение)
  ...other
}) {
return (
    <div className={`step-buy__content-header ${className}`}>
      <label className="step-buy__content-row_label">Способ оплаты</label>
      <div className="step-buy__content-header_tabs">
        {tabs.map((tab) => {
          if (tab.permission && !tab.permission()) return null;

          return (
            <button
              key={tab.value}
              onClick={() => onChangeTab(tab.value)}
              className="step-buy__content-header_tabs-button"
            >
              <div className="step-buy__content-header_tabs-button-wrapper">
                <div
                  className={`step-buy__content-header_tabs-button-label ${
                    currentTab === tab.value
                      ? 'step-buy__content-header_tabs-button-label--selected'
                      : ''
                  }`}
                >
                  {tab.label}
                </div>
                {tab.sublabel && (
                  <div className="step-buy__content-header_tabs-button-sublabel">
                    {tab.sublabel}
                  </div>
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}
