import TypeConst from "./consts";

export function setPaymentStatuses(paymentStatuses) {
    return {
        type: TypeConst.SET_PAYMENT_STATUSES,
        paymentStatuses,
    }
}

export function setPaymentTypes(paymentTypes) {
    return {
        type: TypeConst.SET_PAYMENT_TYPES,
        paymentTypes,
    }
}
export function setBookingTypes(bookingTypes) {
    return {
        type: TypeConst.SET_BOOKING_TYPES,
        bookingTypes,
    }
}
export function setBookingStatuses(bookingStatuses) {
    return {
        type: TypeConst.SET_BOOKING_STATUSES,
        bookingStatuses,
    }
}
export function setBookingTimeStatuses(bookingTimeStatuses) {
    return {
        type: TypeConst.SET_BOOKING_TIME_STATUSES,
        bookingTimeStatuses,
    }
}
export function setBookingTimeTypes(bookingTimeTypes) {
    return {
        type: TypeConst.SET_BOOKING_TIME_TYPES,
        bookingTimeTypes,
    }
}
