//Реакт
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
//Api и библиотеки
//Модули и контент
import { ContextApp } from "../../../../modules/app/appContext";
import { useBalanceContext } from '../../balanceContext';
//Компоненты
import Input from '../../../booking/components/input';
import Button from '../../../../components/button/button';
import InputNumber from '../../../../components/inputs/number'
import Select from '../../../../components/inputs/select';
import InputText from '../../../../components/inputs/text/text';
//Mui icons & components
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
//Стили
import './styles.css';

export default function StepBuy({ nextStep, handleBuyBalance, handleCreateBalance }) {
    const { checkPermission } = useContext(ContextApp);
    const [_isContactsOpen, set_isContactsOpen] = useState(false);
    const balanceOptions = useSelector(state => state.accountOption.availableProducts);
    const defaultBalances = useSelector(state => state.accountOption.defaultBalances);

    const {
        buyBalanceData,
        setBalanceId,
        setBalanceOptionId,
        setName,
        setEmail,
        setPhone,
    } = useBalanceContext();

    const {
        promocode,
        user,
        balances,
        balanceId,
        balanceOptionId,
    } = buyBalanceData;

    const [_tab, set_tab] = useState(0);
    const { promocodeString, promocodeLabel, promocodeErrors, promocodeDiscount } = promocode;
    const { name, email, phone } = user;

    const handleChangeIndex = (index, id) => {
        set_tab(index);
        setBalanceId(id);
        setBalanceOptionId(null);
    };

    const handleChangeContactsState = () => {
        set_isContactsOpen(!_isContactsOpen)
    }

    const handleBalanceOptionChange = (event) => {
        setBalanceOptionId(event.target.value)
    }

    const handlePhoneChange = (event) => {
        let str = event.target.value.replace(/[^0-9]/g, '');
        if (str.length && str[0] !== '7') str = `7${str}`;
        if (str.length >= 11) str = str.substr(0, 11);
        setPhone(str);
    }

    useEffect(()=>{
        if(balances.length >=0 && balances[0]) {
            set_tab(0);
            setBalanceId(balances[0].id)
        } else if(Object.keys(defaultBalances).length > 0 ) {
            set_tab(Object.keys(defaultBalances)[0])
            setBalanceId(Object.keys(defaultBalances)[0])
        }
    }, [balances, defaultBalances])

    return (
        <div className="step-buy__content">
            {balances.length > 0 ?
                <div className='step-buy__content--container'>
                    <div className='step-buy__content-header'>
                        <div className='step-buy__content-header_tabs'>
                        {balances.map((balance, index) => (
                            <button 
                                key={balance.id}
                                onClick={() => handleChangeIndex(index, balance.id)} 
                                className={`${_tab === index ? 'step-buy__content-header_tabs-button--selected' : 'step-buy__content-header_tabs-button'}`}
                            >
                                {balance.type_name}
                            </button>
                        ))}
                        </div>
                    </div>
                    <div className="step-buy__content-body">
                        <div className="step-buy__content-row">
                            <InputNumber readOnly={true} value={balances.filter(balance => balance.id == balanceId)[0]?.balance}>Баланс, мин</InputNumber>                            
                        </div>
                        <div className="step-buy__content-row">
                            <Select 
                              options={
                                balanceOptions[balances.find((balance) => balance.id === balanceId)?.type_id] ??
                                balanceOptions[0] ?? []
                              } 
                              value={balanceOptionId} 
                              onChange={handleBalanceOptionChange}>Пополнить</Select>
                        </div>
                        <div className="step-buy__content-row">
                        </div>
                        <Button className="step-buy__content-button" onClick={handleBuyBalance}>
                            Оформить
                        </Button>
                    </div>
                    
                </div>
            : 
                <div className='step-buy__content--container'>
                    {
                        Object.keys(defaultBalances).length > 0 ?
                        <div className='step-buy__content-header'>
                            <div className='step-buy__content-header_tabs'>
                                {Object.keys(defaultBalances).map((key) => (
                                    <button 
                                        key={key} // Добавьте уникальный ключ для каждого элемента
                                        onClick={() => handleChangeIndex(key, key)} 
                                        className={`${_tab === key ? 'step-buy__content-header_tabs-button--selected' : 'step-buy__content-header_tabs-button'}`}
                                    >
                                        {defaultBalances[key]}
                                    </button>
                                ))}
                            </div>
                            <div className="step-buy__content-body">
                                <div className="step-buy__content-row">
                                    <InputText readOnly={true} value={"Депозит пуст"}>Баланс, мин</InputText>                            
                                </div>
                                <div className="step-buy__content-row">
                                    <Select 
                                      options={
                                        balanceOptions[balances.find((balance) => balance.id === balanceId)?.type_id] ??
                                        balanceOptions[0] ?? []
                                      } 
                                      value={balanceOptionId} 
                                      onChange={handleBalanceOptionChange}>
                                        Пополнить
                                    </Select>
                                </div>
                                <div className="step-buy__content-row">
                                </div>
                                <Button className="step-buy__content-button" onClick={handleCreateBalance}>
                                    Оформить
                                </Button>
                            </div>
                        </div>
                        : 
                        <span>Нет доступных депозитов</span>
                    }
                </div>
            }
        </div>
    );
}
