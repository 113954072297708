import TypeConst from "./consts";

export default function todosReducer(state = {
    availableProducts: {},
    orderProducts: {},
    orderPromocodes: {},
    userCertificates: {},
    defaultBalances: {},
}, action) {
    switch (action.type) {
        case TypeConst.SET_AVAILABLE_PRODUCTS:
            return {
                ...state,
                availableProducts: action.availableProducts
            };
        case TypeConst.SET_ORDER_PRODUCTS:
            return {
                ...state,
                orderProducts: action.orderProducts
            };
        case TypeConst.SET_ORDER_PROMOCODES:
            return {
                ...state,
                orderPromocodes: action.orderPromocodes
            };
        case TypeConst.SET_DEFAULT_BALANCES:
            return {
                ...state,
                defaultBalances: action.defaultBalances
            };
        case TypeConst.SET_USER_CERTIFICATES:
            return {
                ...state,
                userCertificates: action.userCertificates
            };
        default:
            return state;
    }
}
