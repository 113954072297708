import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class TimesFormatter extends Formatter {
  static CustomItem(data) {
    return {
      created_at: this.Date(data.created_at),
    };
  }
}
export default class ApiTimes extends ApiModelWithAuth {
  pathDef = 'authorized/account/booking/times';

  formatterItem = TimesFormatter.Item;
}
