//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ViewHandler from "../../../../../inc/ViewHandler";
import ApiProduct from "../../../../../app/api/authorized/admin/content/ApiProduct";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import Select from "../../../../components/inputs/select";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NumbersIcon from '@mui/icons-material/Numbers';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
//Стили
import './styles.css';

export default function ProductIndex({ ...other }) {
    return <Page {...other}>
        <ProductIndexContent {...other} />
    </Page>
}

function ProductIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_productCategory, set_productCategory] = useState('');
    const [_price, set_price] = useState('');
    const [_oldPrice, set_oldPrice] = useState('');
    const [_minutes, set_minutes] = useState('');

    
    const productCategories = useSelector(state => state.adminOption.productCategories);

    const handleProductCategoryChange = (event) => {
        set_productCategory(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Продукты`);
        contextContent.setTitle(`Продукты`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            <div className="header-row__center">
                <InterestsOutlinedIcon/>
                <div className="header-row__center_text">Продукт</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="product_category"
            name="product_category"
            isSort={false}
        >
            <div className="header-row__center">
                <CategoryOutlinedIcon/>
                <div className="header-row__center_text">Категория</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            <div className="header-row__center">
                <AccessTimeFilledIcon/>
                <div className="header-row__center_text">Время</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="price"
            name="price"
        >
            <div className="header-row__center">
                <CurrencyRubleIcon/>
                <div className="header-row__center_text">Цена</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="old_price"
            name="old_price"
        >
            <div className="header-row__center">
                <MoneyOffIcon/>
                <div className="header-row__center_text">Старая цена</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
        >
            <div className="header-row__center">
                <FontDownloadIcon/>
                <div className="header-row__center_text">Активация</div> 
            </div>  
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>  
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Продукт</InputText>,
        <InputNumber className="input_wf" value={_minutes} onChange={(e) => { set_minutes(e.target.value) }}>Время</InputNumber>,
        <InputNumber className="input_wf" value={_price} onChange={(e) => { set_price(e.target.value) }}>Цена</InputNumber>,
        <InputNumber className="input_wf" value={_oldPrice} onChange={(e) => { set_oldPrice(e.target.value) }}>Старая Цена</InputNumber>,
        <Select className="input_wf options-w_200-r" options={productCategories} value={_productCategory} onChange={handleProductCategoryChange}>Категория</Select>,
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
    ];
    
    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-products_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="name" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="product_category" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.product_category}</ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="minutes" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="price" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.price}</ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="old_price" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row.old_price}</ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="is_active" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>
                {
                    row.is_active ? <div className="is-active_icon--success"><VerifiedIcon style={{fill: 'rgb(80 157 207)' }}/></div>
                    : <div className="is-active_icon--danger"><NewReleasesIcon/></div> 
                }
            </ListBodyItem>,
            <ListBodyItem className="admin-products_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiProduct}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, category: _productCategory, minutes: _minutes, price: _price, oldPrice: _oldPrice }}
        />
    </>
}
