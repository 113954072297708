import routes from "../routes";
let __routes = {};
export class Route{
    name = "";
    mask = "";
    permission = "";

    constructor(name, params = {mask: "", permission: ""}) {
        this.name = name;
        this.mask = params.mask ? params.mask : route(name);
        this.permission = params.permission ? params.permission : '';
    }
    url(params = {}){
        //console.log(this.name, __routes);
        return setParams(this.mask, params);
    }
}

export function route(name, params = {}){
    return setParams(routeMask(name), params);
}
export function routeMask(name){
    if(name in __routes) return __routes[name];
    let url = parseName(name);
    __routes[name] = url;
    return __routes[name];
}
export function childs(name){
    return parseName(name, false);
}
export function getRouteByPath(){

}

function setParams(url, params){
    if(!Object.keys(params).length) return url;
    if(url.includes(':')) {
        for(let key in params){
            let item = params[key];
            if(item) url = url.replaceAll(`:${key}`, item);
        }
    } else {
        url = url + '?'
        for(let key in params){
            let item = params[key];
            if(item) url = url + key + '=' + item + '&'
        }
    }
    if(url.includes(':')) return null;
    return url;
}
function parseName(path, isFullPath = true){
    let parts = path.split('.');
    let max = parts.length - 1;
    let url = '';
    let childs = routes;
    try{
        for(let i = 0; i < parts.length; i++){
            let part = parts[i];
            if(part in childs){
                childs = childs[part];
                if(typeof childs === 'string'){
                    return `${url}${(childs !== '/') ? childs : ''}`;
                }
                else if(isFullPath && i >= max) throw new Error("Child isn't string");
                if(url) url = `${url}/${part}`;
                else url = `/${part}`;
            }else throw new Error("None path in childs");
        }
        if(isFullPath) throw new Error("None path in routes");
        let arr = {};
        for(let path in childs){
            let value = childs[path];
            if(typeof value === 'string') arr[path] = `${url}${(value !== '/') ? value : ''}`;
        }
        return arr;
    }catch(error){
        console.error(error);
    }
    throw new Error(`None "${path}" in routes`);
}
