//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiOrderCertificates from "../../../../../app/api/authorized/account/order/ApiOrderCertificates";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import MultiSelect from "../../../../components/inputs/milti-select";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
//Mui icons
import NumbersIcon from '@mui/icons-material/Numbers';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
//Стили
import './styles.css';

export default function AccountCertificateIndex({ ...other }) {
    return <Page {...other}>
        <AccountCertificateIndexContent {...other} />
    </Page>
}

function AccountCertificateIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_code, set_code] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_promocodeIds, set_promocodeIds] = useState([]);
    const [_dateStart, set_dateStart] = useState('');
    const [_dateEnd, set_dateEnd] = useState('')

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };
    const handlePromocodesChange = (event) => {
        set_promocodeIds(event.target.value);
    }

    const products = useSelector(state => state.accountOption.orderProducts);
    const promocodes = useSelector(state => state.accountOption.orderPromocodes);

    useLayoutEffect(() => {
        contextPage.setTitle(`Мои сертификаты`);
        contextContent.setTitle(`Мои сертификаты`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="code"
            name="code"
        >
            <div className="header-row__center">
                <ReceiptLongOutlinedIcon/>
                <div className="header-row__center_text">Сертификат</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="total"
            name="total"
        >
            <div className="header-row__center">
                <CurrencyRubleIcon/>
                <div className="header-row__center_text">Итого</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            <div className="header-row__center">
                <InterestsOutlinedIcon/>
                <div className="header-row__center_text">Продукт</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            <div className="header-row__center">
                <QrCode2OutlinedIcon/>
                <div className="header-row__center_text">Промокод</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="created_at"
            name="created_at"
        >
            <div className="header-row__center">
                <EventAvailableIcon/>
                <div className="header-row__center_text">Создан</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="updated_at"
            name="updated_at"
        >
            <div className="header-row__center">
                <EditCalendarIcon/>
                <div className="header-row__center_text">Изменен</div> 
            </div>
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="create"
        //     name="create"
        //     isSort={false}
        // >
        //     {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        // </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_code} onChange={(e) => { set_code(e.target.value) }}>Код</InputText>,
        <MultiSelect className="input_wf option-border-none options-w_200-r" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <MultiSelect className="input_wf option-border-none" options={promocodes} value={_promocodeIds} onChange={handlePromocodesChange}>Промокоды</MultiSelect>,
        <InputDatetime className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Дата создания с</InputDatetime>,
        <InputDatetime className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Дата создания до</InputDatetime>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="account-certificates_column-width" key="code" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}><a target="_blank" href={row.file}>{row.code}</a></ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="total" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="product" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>
                {row.products.map((product) => (
                <div key={product.id}>
                    <div>Время: {product.minutes}</div>
                    <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="promocode" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="status" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.status}</ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="created_at" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="updated_at" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="account-certificates_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderCertificates}
            renderRow={renderRow}
            searchParams={
                { 
                    code: _code,
                    products: _productIds,
                    promocodes: _promocodeIds,
                    dateStart: _dateStart, 
                    dateEnd: _dateEnd 
                }
            }
        />
    </>
}
