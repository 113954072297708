//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiBooking from "../../../../../app/api/authorized/account/booking/ApiBookings";
import ViewHandler from "../../../../../inc/ViewHandler";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
import ModalYesNo from "../../../../components/modals/modal-yes-no/modal-yes-no";
//Компоненты
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show/button-link__show";
//MUI Icons
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import NumbersIcon from '@mui/icons-material/Numbers';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
//Стили
import './styles.css';
import Select from "../../../../components/inputs/select";

export default function AccountBookingIndex({ ...other }) {
    return <Page {...other}>
        <AccountBookingIndexContent {...other} />
    </Page>
}

function AccountBookingIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [isModalCancel, setIsModalCancel] = useState(false);

    const [_bookingTypeId, set_bookingTypeId] = useState('');
    const [_statusId, set_statusId] = useState('');
    const [_orderId, set_orderId] = useState('');
    const [_bookingId, set_bookingId] = useState('');
    const [_dateStart, set_dateStart] = useState('');
    const [_dateEnd, set_dateEnd] = useState('');
    
    const [_idToDelete, set_idToDelete] = useState(null);

    const bookingTypes = useSelector(state => state.commonOption.bookingTypes);
    const statuses = useSelector(state => state.commonOption.bookingStatuses);

    const handleBookingTypeChange = (event) => {
        set_bookingTypeId(event.target.value);
    };

    const handleStatusChange = (event) => {
        set_statusId(event.target.value)
    }

    const handleOpenCancelModal = (id) => {
        setIsModalCancel(true);
        set_idToDelete(id);
    }

    const handleCancelBooking = () => {
        const fetchData = async () => {
            try {
                const apiDestroy = ApiBooking.Destroy(_idToDelete);
                const responseData = await apiDestroy.getResponse();
                set_idToDelete(null);
            
            } catch (error) {}
        };

        fetchData();
        setIsModalCancel(false);
    }

    useLayoutEffect(() => {
        contextPage.setTitle(`Мои бронирования`);
        contextContent.setTitle(`Мои бронирования`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="order_id"
            name="order_id"
        >
            <div className="header-row__center">
                <ShoppingCartIcon/>
                <div className="header-row__center_text">Заказ</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            <div className="header-row__center">
                <TodayIcon/>
                <div className="header-row__center_text">Начало</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            <div className="header-row__center">
                <EventIcon/>
                <div className="header-row__center_text">Окончание</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            <div className="header-row__center">
                <TimelapseIcon/>
                <div className="header-row__center_text">Минуты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_id"
            name="type_id"
        >
            <div className="header-row__center">
                <ShoppingCartCheckoutIcon/>
                <div className="header-row__center_text">Приобритен через</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status_id"
            name="status_id"
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус бронирования</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment"
            name="payment"
            isSort={false}
        >
            <div className="header-row__center">
                <CreditScoreIcon/>
                <div className="header-row__center_text">Оплата</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="orderPaymentStatus"
            name="orderPaymentStatus"
        >
            <div className="header-row__center">
                <AssuredWorkloadIcon/>
                <div className="header-row__center_text">Статус оплаты</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="count_people"
            name="count_people"
        >
            <div className="header-row__center">
                <GroupAddIcon/>
                <div className="header-row__center_text">Количество человек</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
    ];

    const filters = [
        <Select className="input_wf" options={bookingTypes} value={_bookingTypeId} onChange={handleBookingTypeChange}>Тип оплаты</Select>,
        <Select className="input_wf" options={statuses} value={_statusId} onChange={handleStatusChange}>Статус</Select>,
        <InputNumber className="input_wf" value={_orderId} onChange={(e) => { set_orderId(e.target.value) }}>№ заказа</InputNumber>,
        <InputNumber className="input_wf" value={_bookingId} onChange={(e) => { set_bookingId(e.target.value) }}>№ бронирования</InputNumber>,
        <InputDatetime className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Начало после</InputDatetime>,
        <InputDatetime className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Окончание до</InputDatetime>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="account-bookings_column-width" key="order_id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{ row.order_id }</ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="date_start" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="date_end" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="minutes" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="type_id" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>
                <div>{row.type_name}</div>
                <div className="account__order__column--nonactive">{row.balance_user_name ?? null}</div>
            </ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="status_id" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>
                <div className="account__booking-status">
                    <div>{row.status_name}</div>
                    {row.status_id == 2 && row.type_id == 3 && contextContent.checkPermission('destroy')
                        ?
                        <div onClick={() => {handleOpenCancelModal(row.id)}} className="account__booking-status_icon">
                            <CancelOutlinedIcon />
                        </div>
                        : null
                    }
                </div>
            </ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="payment" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>
                {row.order_total ? <div>{row.order_total} ₽</div> : null}
                {row.payment_type_name ? <div>{row.payment_type_name}</div> : null}
                {row.time_minutes ? <div>{row.time_minutes}</div> : null}
                {row.time_status_comment ? <div className="account__order__column--nonactive">{row.time_status_comment}</div> : null}
            </ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="orderPaymentStatus" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{
                <div className="account__order-payment-status">
                    <div>{row.order_payment_status_name}</div>
                    {row.order_payment_status_id == 1  && row.type_id == 2 && contextContent.checkPermission('purchase')
                        ?
                        <a target="_blank" href={row.order_payment_link} className="account__order-payment-status_icon">
                            <AddCardOutlinedIcon />
                        </a>
                        : null
                    }
                </div>
            }</ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="count_people" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.count_people}</ListBodyItem>,
            <ListBodyItem className="account-bookings_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[9].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBooking}
            renderRow={renderRow}
            searchParams={{ bookingType: _bookingTypeId, status: _statusId, orderId: _orderId, bookingId: _bookingId, dateStart: _dateStart, dateEnd: _dateEnd }}
        />
        <ModalYesNo
            isOpenModal={isModalCancel} 
            closeModal={()=>setIsModalCancel(false)}
            actionBtnNo={()=>setIsModalCancel(false)}
            actionBtnYes={handleCancelBooking}
            title={`Вы действительно хотите отменить бронирование?`}
        />
    </>
}
