import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Select from '../../../../components/inputs/select';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import './styles.css';

export default function List({ items, title='', isOpen=true, setIsOpen = ()=> {},selectedItems, placeholder, addItem, deleteItem, className, children }) {
    const handleDeleteItem = (itemId) => {
        if (itemId) {
            deleteItem(itemId)
        }
    };
    const handleCurrItemChange = (e) => {
        if (e.target.value) {
            addItem(e.target.value)
        }
    }
    const handleChangeOpenStatus = () => {
        setIsOpen(!isOpen);
    }
    return (
        <div className={`${className}`}>
           {items != null ? <div className='list-header'>
                <Select
                    options={items}
                    value={null}
                    onChange={handleCurrItemChange}
                    isSpreaded={false}
                    placeholder={placeholder}
                >
                </Select>
            </div>
            : null}

            <div className='list-body'>
                {title ?
                    <div className='list-body__header' onClick={handleChangeOpenStatus}>
                        <div className='list-body__header_title'>
                            <div>
                                {title}
                            </div>
                            {selectedItems.length > 0 ?
                                <div className='list-body__header_title-count'>
                                    {selectedItems.length}
                                </div>
                            : <div className='list-body__header_title-count'>
                                    {0}
                              </div>
                            }
                        </div>
                        <div className={`list-body__header_icon ${isOpen ? 'rotated' : ''}`}>
                            {<KeyboardArrowUpOutlinedIcon />}
                        </div>
                    </div>
                : null}
                {selectedItems.map(selectedItem => (
                    <div  key={selectedItem.id} className={`list-body__item ${isOpen ? 'open' : 'close'}`}>
                        <div className='list-body__item-header'>
                            <div className='list-body__item-name'>
                                <span>{selectedItem.name}</span>
                            </div>
                            <div className='list-body__item-icons'>
                                <CloseIcon className='list-body__item-icon' onClick={() => handleDeleteItem(selectedItem.id)} />
                            </div>
                        </div>
                        <div className='list-body__item-mods'>
                            {children(selectedItem)} 
                        </div>
                    </div>

                ))}
            </div>

        </div>
    );
}