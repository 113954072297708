// React
import React, { useEffect, useState } from 'react';
//Mui Icons
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// Компоненты
import Select from '../../../../components/inputs/select';
import List from '../list';
import InputCheckbox from '../../../../components/inputs/checkbox/checkbox';
import Button from '../../../../components/button/button';
//Mui icons
import EventIcon from '@mui/icons-material/Event';
// Стили
import './styles.css';

export default function DayParams(
    {
        calendarStatuses, 
        selectedDates,
        selectedCalendarStatus, 
        handleCalendarStatusChange, 
        handleChangeModsOnCategory,
        productCategories, 
        selectedCategories,
        addSelectedCategory,
        deleteSelectedCategory,
        listIsOpen,
        handleIsListOpen,
        deleteSelectedDate,
        listDateIsOpen,
        handleIsListDateOpen,
        closeDayParams,
        handleSetDayParams,
        dropCategory,
        dropStatus
    }) {

    useEffect(() => {
        if (selectedCalendarStatus) {
            handleCalendarStatusChange({target: {value: selectedCalendarStatus}})
        }
    }, [])

    const handleDeleteSelectedDate = (e) => {
        deleteSelectedDate(selectedDates[e -1]);
    } 

    return <>
        <Select
            options={calendarStatuses}
            value={selectedCalendarStatus}
            onChange={handleCalendarStatusChange}
            isSpreaded={false}
            placeholder="Тип дня"
        >
            Выставить статус
        </Select>
        <List
            title='Категории'
            className="calendar__header_center-list"
            items={productCategories}
            selectedItems={selectedCategories}
            addItem={addSelectedCategory}
            deleteItem={deleteSelectedCategory}
            isOpen={listIsOpen}
            setIsOpen={handleIsListOpen}
            placeholder="Категория"
        >
            {(item) => {
                return (
                    <div>
                        <InputCheckbox
                            value={item.is_active}
                            onClick={() => handleChangeModsOnCategory(item)}
                        />
                        Актив.
                    </div>
                );
            }}
        </List>
        {selectedDates ? 
                    <List
                    title='Дни'
                    className="calendar__header_center-list"
                    items={null}
                    selectedItems={selectedDates}
                    addItem={() => {}}
                    deleteItem={handleDeleteSelectedDate}
                    isOpen={listDateIsOpen}
                    setIsOpen={handleIsListDateOpen}
                >
                    {(item) => {
                        return (
                            item.id ? 
                                <div className='calendar__header_center-list-day-item'>
                                    {item.dateString ?? null}
                                    <EventIcon/>
                                </div>
                            : null
                        );
                    }}
                </List>
        : null}
        <div className='contentGroupBox__dropdown--row'>
            <div>
                <div onClick={dropCategory} className='contentGroupBox__dropdown'>
                    <CloseOutlinedIcon className='contentGroupBox__dropdown_cross ' />
                    <div className='contentGroupBox__dropdown_link'>Сбросить</div>
                </div>
            </div>
            <div className='contentGroupBox__dropdown--buttons'>
                <Button onClick={closeDayParams} className='contentGroupBox__dropdown--buttons-decline'>Отменить</Button>
                <Button onClick={handleSetDayParams} className='contentGroupBox__dropdown--buttons-success'>Применить</Button>
            </div>
        </div>
    </>
}