//Реакт
import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Модули Content
import pages from "./pages";
import { ContextApp } from "../modules/app/appContext"
//Системные представления
import Abort404 from "./public/system/Abort404";

export default function Pages() {
    const { checkPermission } = useContext(ContextApp);
    
    return <BrowserRouter>
        <Routes>
            {pages.map((item, i) => {
                const Page = item.Page;
                if(item.route.permission) {
                    if(checkPermission(item.route.permission.name)){
                        return <Route
                            key={item.route.name}
                            path={item.route.mask}
                            element={
                                <Page
                                    path={item.path}
                                    route={item.route}
                                    routes={item.routes}
                                    type={item.type}
                                    withContent={true}
                                />
                            }
                        />
                    }
                } else {
                    return <Route
                        key={item.route.name}
                        path={item.route.mask}
                        element={
                            <Page
                                path={item.path}
                                route={item.route}
                                routes={item.routes}
                                type={item.type}
                                withContent={true}
                            />
                        }
                    />
                }
            })}
            <Route path="*" element={<Abort404 />} />
        </Routes>
    </BrowserRouter>
}
