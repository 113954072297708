import TypeConst from "./consts";

export function setAvailableProducts(availableProducts) {
    return {
        type: TypeConst.SET_AVAILABLE_PRODUCTS,
        availableProducts,
    }
}
export function setOrderProducts(orderProducts) {
    return {
        type: TypeConst.SET_ORDER_PRODUCTS,
        orderProducts,
    }
}
export function setDefaultBalances(defaultBalances) {
    return {
        type: TypeConst.SET_DEFAULT_BALANCES,
        defaultBalances,
    }
}
export function setUserCertificates(userCertificates) {
    return {
        type: TypeConst.SET_USER_CERTIFICATES,
        userCertificates,
    }
}
export function setOrderPromocodes(orderPromocodes) {
    return {
        type: TypeConst.SET_ORDER_PROMOCODES,
        orderPromocodes,
    }
}
export function setPaymentStatuses(paymentStatuses) {
    return {
        type: TypeConst.SET_PAYMENT_STATUSES,
        paymentStatuses,
    }
}