//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiOrderCompetitions from "../../../../../app/api/authorized/admin/order/ApiOrderCompetitions";
import ViewHandler from "../../../../../inc/ViewHandler";
import { useLocation } from 'react-router-dom';
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputPhone from "../../../../components/inputs/phone/phone";
import Select from "../../../../components/inputs/select";
import ButtonLinkShow from "../../../../components/button-link/__show";
import MultiSelect from "../../../../components/inputs/milti-select";
// Mui icons
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';  
import Groups2Icon from '@mui/icons-material/Groups2';
import GroupIcon from '@mui/icons-material/Group';
import PinIcon from '@mui/icons-material/Pin';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import PhoneIcon from '@mui/icons-material/Phone';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import ContactlessIcon from '@mui/icons-material/Contactless';
//Стили
import './styles.css';

export default function OrderCompetitionIndex({ ...other }) {
    return <Page {...other}>
        <OrderCompetitionIndexContent {...other} />
    </Page>
}

function OrderCompetitionIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [_id, set_id] = useState(queryParams.get('id') ?? '');
    const [_name, set_name] = useState('');
    const [_competition, set_competition] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_price, set_price] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_promocodeName, set_promocodeName] = useState('');
    const [_payType, set_payType] = useState([])

    const products = useSelector(state => state.adminOption.competitionProducts);
    const payTypes = useSelector(state => state.commonOption.paymentTypes);
    
    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    }; 

    const competitions = useSelector(state => state.adminOption.competitions);

    const handleCompetitionChange = (event) => {
        set_competition(event.target.value);
    };

    const handlePayTypeChange = (event) => {
        set_payType(event.target.value)
    }

    useLayoutEffect(() => {
        contextPage.setTitle(`Соревнования`);
        contextContent.setTitle(`Соревнования`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Заказ</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamName"
            name="competitionTeamName"
        >
            <div className="header-row__center">
                <Groups2Icon/>
                <div className="header-row__center_text">Команда</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="show"
            name="show"
        >
            <div className="header-row__center">
                <GroupIcon/>
                <div className="header-row__center_text">Участники</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamId"
            name="competitionTeamId"
            isSort={false}
        >
            <div className="header-row__center">
                <PinIcon/>
                <div className="header-row__center_text">Номер команды</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamCity"
            name="competitionTeamCity"
        >
            <div className="header-row__center">
                <LocationCityIcon/>
                <div className="header-row__center_text">Город</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competition"
            name="competition"
            isSort={false}
        >
            <div className="header-row__center">
                <SportsScoreIcon/>
                <div className="header-row__center_text">Соревнование</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="competitionTeamDiscipline"
            name="competitionTeamDiscipline"
        >
            <div className="header-row__center">
                <SportsGymnasticsIcon/>
                <div className="header-row__center_text">Дисциплина</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            <div className="header-row__center">
                <PhoneIcon/>
                <div className="header-row__center_text">Телефон</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            <div className="header-row__center">
                <EmailIcon/>
                <div className="header-row__center_text">Email</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="price"
            name="price"
        >
            <div className="header-row__center">
                <CurrencyRubleIcon/>
                <div className="header-row__center_text">Итого</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            <div className="header-row__center">
                <InterestsOutlinedIcon/>
                <div className="header-row__center_text">Продукт</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            <div className="header-row__center">
                <QrCode2OutlinedIcon/>
                <div className="header-row__center_text">Промокод</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            <div className="header-row__center">
                <FmdBadIcon/>
                <div className="header-row__center_text">Статус</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_id"
            name="payment_type_id"
        >
            <div className="header-row__center">
                <ContactlessIcon/>
                <div className="header-row__center_text">Тип оплаты</div> 
            </div>  
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Команда</InputText>,
        <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <Select className="input_wf" options={competitions} value={_competition} onChange={handleCompetitionChange}>Соревнование</Select>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
        <MultiSelect className="input_wf options-w_150-r" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>,
        <MultiSelect className="input_wf options-w_150-r" options={payTypes} value={_payType} onChange={handlePayTypeChange}>Тип оплаты</MultiSelect>,
    ];
    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-competitions_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamName" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>
               <a target="_blank" href={row.team_show_url}>{row.team}</a>
            </ListBodyItem>,
            <ListBodyItem key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.teamShow.url({ id: row.team_id })} /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamId" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{row.team_id}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamCity" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>{row.city}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competition" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{row.competition}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="competitionTeamDiscipline" data-label={ViewHandler.getComponentTextByClass(header[5].props.children, 'header-row__center_text')}>{row.discipline}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="phone" data-label={ViewHandler.getComponentTextByClass(header[6].props.children, 'header-row__center_text')}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="email" data-label={ViewHandler.getComponentTextByClass(header[7].props.children, 'header-row__center_text')}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="price" data-label={ViewHandler.getComponentTextByClass(header[8].props.children, 'header-row__center_text')}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="product" data-label={ViewHandler.getComponentTextByClass(header[9].props.children, 'header-row__center_text')}>
                {row.products.map((product) => (
                    <div key={product.id}>
                        <div>Время: {product.minutes}</div>
                        <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                    </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="promocode" data-label={ViewHandler.getComponentTextByClass(header[10].props.children, 'header-row__center_text')}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-competitions_column-width" key="status" data-label={ViewHandler.getComponentTextByClass(header[11].props.children, 'header-row__center_text')}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-balances_column-width" key="payment_type_id" data-label={ViewHandler.getComponentTextByClass(header[12].props.children, 'header-row__center_text')}>{row.payment_type_name}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderCompetitions}
            renderRow={renderRow}
            searchParams={
                { 
                    id: _id, 
                    name: _name, 
                    competition: _competition, 
                    phone: _phone, 
                    email: _email, 
                    price: _price, 
                    products: _productIds, 
                    promocode: _promocodeName,
                    paymentTypeIds: _payType,
                }
            }
        />
    </>
}
