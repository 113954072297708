import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class CalendarDaySaveFormatter extends Formatter {
    static CustomItem(data) {
        return data;
    }
}
export default class ApiDaySaveCalendar extends ApiModelWithAuth {
    pathDef = 'authorized/admin/event/calendar/save/days';

    getParamsForStore({ selectedDates, selectedCalendarStatus, selectedCategories }) {
        let data = {};
        if (selectedDates) {
            data['dates'] = selectedDates.map(date =>
                new Date(date.year + '-' + date.month + '-' + date.day)
            );
        }
        data['date_status'] = selectedCalendarStatus ?? null;
        // Преобразуем selectedCategories
        if (selectedCategories) {
            data['categories'] = selectedCategories.map(category => ({
                id: category.id,
                is_active: category.is_active
            }));
        } else {
            data['categories'] = null;
        }
        return data;
    }

    formatterItem = CalendarDaySaveFormatter.CustomItem;
}