//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiCalendar from "../../../../../../app/api/authorized/admin/event/ApiCalendar";
import DateLib from "../../../../../../inc/DateLib";
//Модули Content
import Page from "../../../../../modules/page";
import { ContextPage } from "../../../../../modules/page/pageContext";
import { ContextContent } from "../../../../../modules/content/contentContext";
//Компоненты
import Select from "../../../../../components/inputs/select";
import InputDate from "../../../../../components/inputs/date/date";
import InputText from "../../../../../components/inputs/text";
import InputWrapper from "../../../../../components/input/__wrapper";
import InputDatetime from "../../../../../components/inputs/datetime/datetime";
//Стили
import './styles.css';

export default function CalendarShow({ ...other}){
  return <Page ApiModel={ApiCalendar} { ...other}>
    <CalendarShowContent { ...other}/>
  </Page>
}

function CalendarShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_status, set_status] = useState(null);
  const [_dateStart, set_dateStart] = useState(null);
  const [_dateEnd, set_dateEnd] = useState(null);
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  const calendarStatuses = useSelector(state => state.adminOption.calendarStatuses);
  const handleStatusChange = (event) => {
      console.log(event.target.value)
      set_status(event.target.value);
  };

  contextContent._dataToServer.current = {
    status_slug: _status,
    date_start: _dateStart,
    date_end: _dateEnd,
  };

  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_status(data.status_slug);
      set_dateStart(data.date_start);
      set_dateEnd(data.date_end);
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Календарь бронирований:  ${DateLib.formatterDate(data.date_start)} - ${DateLib.formatterDate(data.date_end)}`);
      contextContent.setTitle(`Календарь бронирований: ${DateLib.formatterDate(data.date_start)} - ${DateLib.formatterDate(data.date_end)}`);
      contextPage.setName(`${DateLib.formatterDate(data.date_start)} - ${DateLib.formatterDate(data.date_end)}`);
    };
    contextContent.doLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return <>
    {!contextContent.isCreate ?
      <InputWrapper>
        <InputText className="pages__show-input" value={_id} readOnly={true}>Номер</InputText>
      </InputWrapper> 
    : null }
    <InputWrapper>
        <Select className="pages__show-input" options={calendarStatuses} value={_status} readOnly={!contextContent.isCreateOrEdit} onChange={handleStatusChange}>Статус</Select>
    </InputWrapper>
    <InputWrapper>
        <InputDate className="pages__show-input" value={DateLib.formatterDate(_dateStart)} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateStart(e.target.value) }}>Дата начала</InputDate>
    </InputWrapper>
    <InputWrapper>
        <InputDate className="pages__show-input" value={DateLib.formatterDate(_dateEnd)} readOnly={!contextContent.isCreateOrEdit} onChange={(e) => { set_dateEnd(e.target.value) }}>Дата окончания</InputDate>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
