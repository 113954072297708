//Реакт
import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../../inc/DateLib";
import ApiCalendar from "../../../../../../app/api/authorized/admin/event/ApiCalendar";
import ApiCategoryCalendar from "../../../../../../app/api/authorized/admin/event/ApiCategoryCalendar";
import { route } from "../../../../../../inc/Routes";
//Модули Content
import Page from "../../../../../modules/page";
import { ContextPage } from "../../../../../modules/page/pageContext";
import { ContextContent } from "../../../../../modules/content/contentContext";
import { ContextApp } from "../../../../../modules/app/appContext";
//Модули view
import List from "../../../../../modules/list";
import ListHeaderItem from "../../../../../modules/list/__header-item";
import ListBodyItem from "../../../../../modules/list/__body-item";
import Calendar from "../../../../../modules/calendar/calendar";
//Компоненты
import Select from "../../../../../components/inputs/select";
import InputDate from "../../../../../components/inputs/date/date";
import InputNumber from "../../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../../components/button-link/__add";
//Mui icons
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css';

export default function CalendarIndex({ ...other }) {

     const [searchParams, setSearchParams] = useState({
        dayCalendar: {},
        categoryCalendar: {},
    });

    const handleChangeDayCalendarSearchParams = (params) => {
        setSearchParams((prev) => ({ ...prev, dayCalendar: params }));
    }

    const handleChangeCategoryCalendarSearchParams = (params) => {
        setSearchParams((prev) => ({ ...prev, categoryCalendar: params }));
    }

    const tabs = {
        'General ': 'Общий календарь',
        'DayCalendar': 'Статус дней',
        'CategoryCalendar': 'Календарь категорий',
    }
    
    const [activeComponent, setActiveComponent] = useState(Object.keys(tabs)[0]);

    // Функция для переключения компонентов
    const renderComponent = () => {
        switch (activeComponent) {
            case 'General ':
                return <GeneralCalendarIndexContent {...other}/>;
            case 'DayCalendar':
                return <CalendarIndexContent 
                    setSearchParams={handleChangeDayCalendarSearchParams} 
                    searchParams={searchParams.dayCalendar} 
                    {...other}/>;
            case 'CategoryCalendar':
                return <CategoryCalendarIndexContent 
                    setSearchParams={handleChangeCategoryCalendarSearchParams} 
                    searchParams={searchParams.categoryCalendar} 
                    {...other}/>;
            default:
                return <GeneralCalendarIndexContent {...other}/>;
        }
    };

    const handleSelectTab = (tab) => {
        setActiveComponent(tab);
    }

    return <Page
        tabs={tabs}
        currentTab={activeComponent}
        onSelectTab={handleSelectTab}
        {...other}
        className="content-overflow-visible"
    >
        {renderComponent()}
    </Page>
}

function CalendarIndexContent({setSearchParams, searchParams, ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const { checkPermission } = useContext(ContextApp);

    const [_id, set_id] = useState(searchParams.id ?? '');
    const [_statusId, set_statusId] = useState(searchParams.status ?? '');
    const [_dateStart, set_dateStart] = useState(searchParams.dateStart ?? '');
    const [_dateEnd, set_dateEnd] = useState(searchParams.dateEnd ?? '');

    const calendarStatuses = useSelector(state => state.adminOption.calendarStatuses);

    const handleStatusChange = (event) => {
        set_statusId(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle('Статус дней');
    }, [contextContent, contextPage]);

    useEffect(() => {
        setSearchParams({ 
            id: _id, 
            status: _statusId, 
            dateStart: _dateStart, 
            dateEnd: _dateEnd 
        })
    }, [_id, _statusId, _dateStart, _dateEnd ])

    useLayoutEffect(() => {
        contextPage.setTitle(`Календари бронирований`);
        contextContent.setTitle(`Календари бронирований`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="status_id"
            name="status_id"
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Начало
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Окончание
        </ListHeaderItem>,
    ];

    const filters = [
        <Select className="input_wf" options={calendarStatuses} value={_statusId} onChange={handleStatusChange}>Статус</Select>,
        <InputDate className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Начало</InputDate>,
        <InputDate className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Окончание</InputDate>,
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>Номер</InputNumber>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-calendars_column-width" key="show">
                {checkPermission('admin.calendars.show') ? <ButtonLinkShow target="_blank" href={route('authorized.admin.event.calendar.calendars.show',{id: row.id})}  /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-calendars_column-width" key="id" data-label={header[1].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-calendars_column-width" key="status_id" data-label={header[2].props.children}>{row.status_name}</ListBodyItem>,
            <ListBodyItem className="admin-calendars_column-width" key="date_start" data-label={header[3].props.children}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="admin-calendars_column-width" key="date_end" data-label={header[4].props.children}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiCalendar}
            renderRow={renderRow}
            searchParams={searchParams}
        />
    </>
}

function CategoryCalendarIndexContent({setSearchParams, searchParams, ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const { checkPermission } = useContext(ContextApp);

    const [_id, set_id] = useState(searchParams.id ?? '');
    const [_categoryId, set_categoryId] = useState(searchParams.category ?? '');
    const [_dateStart, set_dateStart] = useState(searchParams.dateStart ?? '');
    const [_dateEnd, set_dateEnd] = useState(searchParams.dateEnd ?? '');

    const categories = useSelector(state => state.adminOption.productCategories);

    const handleCategoryChange = (event) => {
        set_categoryId(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle('Календарь категорий');
    }, [contextContent, contextPage]);

    useEffect(() => {
        setSearchParams({ 
            id: _id, 
            category: _categoryId, 
            dateStart: _dateStart, 
            dateEnd: _dateEnd 
        })
    }, [_id, _categoryId, _dateStart, _dateEnd ])

    useLayoutEffect(() => {
        contextPage.setTitle(`Календари категорий`);
        contextContent.setTitle(`Календари категорий`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
            className="create_mobile"
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd  target="_blank" href={route(`authorized.admin.event.calendar.categories.create`)} /> : null}
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="category_id"
            name="category_id"
        >
            Категория
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Начало
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Окончание
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_off"
            name="is_off"
        >
            Статус
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <Select className="input_wf" options={categories} value={_categoryId} onChange={handleCategoryChange}>Категория</Select>,
        <InputDate className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Начало</InputDate>,
        <InputDate className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Окончание</InputDate>
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-calendar-category_column-width" key="show">
                {checkPermission('admin.calendars.show') ? <ButtonLinkShow target="_blank"  href={route('authorized.admin.event.calendar.categories.show',{id: row.id})} /> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="id" data-label={header[1].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="category_id" data-label={header[2].props.children}>{row.category_name}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="date_start" data-label={header[3].props.children}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="date_end" data-label={header[4].props.children}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="is_off" data-label={header[5].props.children}>
                {
                    row.is_off ? <div className="is-off_content"><div className="is-off_icon--danger"><NewReleasesIcon/></div><div className="is-off_text">Не активно</div></div>
                    :<div className="is-off_content"><div className="is-off_icon--success"><VerifiedIcon style={{fill: 'rgb(80 157 207)' }}/></div><div className="is-off_text">Активно</div></div>
                }
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiCategoryCalendar}
            renderRow={renderRow}
            searchParams={searchParams}
        />
    </>
}

function GeneralCalendarIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    useLayoutEffect(() => {
        contextPage.setTitle(`Общий календарь`);
        contextContent.setTitle(`Общий календарь`);
    }, [contextContent, contextPage]);
    
    return <>
        <div className="calendar-wrapper">
            <Calendar/>
        </div>
    </>
}
