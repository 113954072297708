const consts = {
    SET_AVAILABLE_PRODUCTS:                  "SET_AVAILABLE_PRODUCTS",
    SET_ORDER_PRODUCTS:                      "SET_ORDER_PRODUCTS",
    SET_ORDER_PROMOCODES:                    "SET_ORDER_PROMOCODES",
    SET_PAYMENT_STATUSES:                    "SET_PAYMENT_STATUSES",
    SET_USER_CERTIFICATES:                   "SET_USER_CERTIFICATES",
    SET_DEFAULT_BALANCES:                    "SET_DEFAULT_BALANCES",
};
  
export default consts;
